import './booking.css';

import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {
    addBlockedCell,
    addBlockedCellIdle,
    checkedBlockedCells,
    checkedBlockedCellsIdle,
    checkedBlockedCellsDate,
    checkedBlockedCellsDateIdle,
    getFreeCells,
    getFreeCellsIdle,
    getListBooking,
    getListBookingIdle,
    updateBlocked,
    updateBlockedIdle,
    updateBooking,
    updateBookingIdle,
    getFreeCellsClear,
    addBooking,
    addBookingIdle,
    getBookingShipment,
    getBookingShipmentIdle,
    getBookingShipmentClear,
    deleteBooking,
    deleteBookingIdle,
    searchBookingIdle,
    searchBookingClear,
    searchBookingReset,
    searchTypeLocationIdle,
    searchTypeLocation,
    searchUsersProviderIdle,
    searchUsersProvider,
    changeCells,
    getServprovName
} from '../../modules/booking/actions';

import {
    DeleteOutlined,
    ClockCircleOutlined,
    CalendarOutlined,
    DragOutlined,
    PlusOutlined,
    CheckOutlined,
    InfoCircleOutlined,
    BackwardOutlined,
    CarOutlined,
    EditOutlined,
    LeftOutlined,
    RightOutlined
} from '@ant-design/icons';

import {
    Button,
    Space,
    Modal,
    Form,
    Input,
    Select,
    Radio,
    Spin,
    Card,
    DatePicker,
    Alert,
    notification,
    Switch,
    Popconfirm,
    Row,
    Col,
    Popover,
    Dropdown,
    theme
} from 'antd';

import dayjs from 'dayjs';

import 'dayjs/locale/ru';
import locale from 'antd/es/date-picker/locale/ru_RU';
import Search from './components/search/search';
import ru from 'dayjs/locale/ru';
import {f, fields, getValueProperty} from '../../constants/config';
import DetailRow from './components/detail/detail';
import ExportForm from './components/export/export';
import {Link} from 'react-router-dom';
import BreadCrumbs from "../../modules/breadCrumbs";

const {useToken} = theme;

var weekday = require('dayjs/plugin/weekday')
dayjs.locale('ru');

dayjs.extend(weekday)

const {Option} = Select;

const {TextArea} = Input;

let timeoutSearchTypeLocation;
let timeoutSearchUsersProvider;

const Booking = (props) => {

    const clearCacheData = () => {
        caches.keys().then((names) => {
            names.forEach((name) => {
                caches.delete(name);
            });
        });
    };

    useEffect(() => {
        clearCacheData();
    }, [])

    const {token} = useToken();
    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
    };

    const dispatch = useDispatch();

    const [checkedSklads, setCheckedsSklads] = useState([]);
    const [dateBookings, setDateBookings] = useState(dayjs());
    const [dateBookingsSelect, setDateBookingsSelect] = useState(dayjs());

    const [isSelectWarehouse, setIsSelectWarehouse] = useState(false);

    const [view, setView] = useState('warehouses');

    const [valueSearchTypeLocation, setValueSearchTypeLocation] = useState();
    const [dataSearchTypeLocation, setDataSearchTypeLocation] = useState([]);

    const [valueSearchUsersProvider, setValueSearchUsersProvider] = useState();
    const [dataSearchUsersProvider, setDataSearchUsersProvider] = useState([]);

    const [filterDirectionDock, setFilterDirectionDock] = useState('');
    const [directionDock, setDirectionDock] = useState([]);
    const [selectBooking, setSelectBooking] = useState(null);
    const [selectSklad, setSelectSklad] = useState(null);
    const [loading, setLoading] = useState(false);
    const [stateCellMouseDown, setStateCellMouseDown] = useState(null);
    const [detailBooking, setDetailBooking] = useState(null);
    const [showDetailBooking, setShowDetailBooking] = useState(null);
    const [showDetailWarehouse, setShowDetailWarehouse] = useState(null);
    const [defaultBookingInfo, setDefaultBookingInfo] = useState(null);

    const [isModalAppointmentOpen, setIsModalAppointmentOpen] = useState(false);
    const [isModalTimesOpen, setIsModalTimesOpen] = useState(false);
    const [isModalAutoOpen, setIsModalAutoOpen] = useState(false);
    const [isModalEditAppOpen, setIsModalEditAppOpen] = useState(false);

    const [showSelectDock, setShowSelectDock] = useState(null);

    const [dragBooking, setDragBooking] = useState(null);
    const [dragTimes, setDragTimes] = useState(null);
    const [dragDock, setDragDock] = useState(null);
    const [dragWarehouse, setDragWarehouse] = useState(null);
    const [dragStateCell, setDragStateCell] = useState('');

    const [searchWarehouse, setSearchWarehouse] = useState(null);

    const [visibleNewFields, setVisibleNewFields] = useState(!false);

    const [activeWarehousesOptions, setActiveWarehousesOptions] = useState([]);
    const [visibleDopFieldsExpeditor, setVisibleDopFieldsExpeditor] = useState(false);
    const [visibleDopFieldsPricep, setVisibleDopFieldsPricep] = useState(false);
    const [visibleDopFieldsCar, setVisibleDopFieldsCar] = useState(false);
    const [organizationIsOOO, setOrganizationIsOOO] = useState(true);

    const dataUser = useSelector(state => state.authApp.init);
    const typeUser = useSelector(state => state.authApp.typeUser);

    const statusBooking = useSelector(state => state.booking.status);
    const bookingWarehouses = useSelector(state => state.booking.warehouses);
    const responseBooking = useSelector(state => state.booking.response);
    const blockedCells = useSelector(state => state.booking.blockedCells);
    const reservedCells = useSelector(state => state.booking.reserved);
    const checkedWarehouses = useSelector(state => state.booking.checkedWarehouses);
    let allBlockedTimeDock = useSelector(state => state.booking.allBlockedTimeDock);
    const checkedDirectionDock = useSelector(state => state.booking.checkedDirectionDock);

    const addBlockedCellStatus = useSelector(state => state.booking.addBlockedCellStatus);
    const updateBookingStatus = useSelector(state => state.booking.updateBookingStatus);

    const addBookingStatus = useSelector(state => state.booking.addBookingStatus);
    const addBookingResult = useSelector(state => state.booking.addBookingResult);

    const statusCheckedBlockedCells = useSelector(state => state.booking.statusCheckedBlockedCells);
    const resultCheckedBlockedCells = useSelector(state => state.booking.resultCheckedBlockedCells);
    const statusUpdateBlocked = useSelector(state => state.booking.statusUpdateBlocked);

    const statusCheckedBlockedCellsDate = useSelector(state => state.booking.statusCheckedBlockedCellsDate);
    const resultCheckedBlockedCellsDate = useSelector(state => state.booking.resultCheckedBlockedCellsDate);

    const getBookingShipmentStatus = useSelector(state => state.booking.getBookingShipmentStatus);
    const getBookingShipmentResult = useSelector(state => state.booking.getBookingShipmentResult);
    let setTimeoutShipment = null;

    const statusGetFreeCells = useSelector(state => state.booking.statusGetFreeCells);
    const resultGetFreeCells = useSelector(state => state.booking.resultGetFreeCells);

    const deleteBookingStatus = useSelector(state => state.booking.deleteBookingStatus);

    const searchBookingStatus = useSelector(state => state.booking.searchBookingStatus);
    const searchBookingResult = useSelector(state => state.booking.searchBookingResult);

    const statusInitData = useSelector(state => state.initData.status);
    const infoWarehouses = useSelector(state => state.initData.warehouses);
    const infoDocks = useSelector(state => state.initData.docks);
    const activeWarehouses = useSelector(state => state.booking.activeWarehouses);
    const timeInfo = useSelector(state => state.initData.time);
    const timeList = useSelector(state => state.initData.timeList);
    const fieldsBookings = useSelector(state => state.initData.fieldsBookings);
    const equipmentsInfo = useSelector(state => state.initData.equipments);
    const typeTrucks = useSelector(state => state.initData.typeTrucks);
    const listUsersProdider = useSelector(state => state.initData.listUsersProdider);
    const listLocations = useSelector(state => state.initData.listLocations);
    const listProvider = useSelector(state => state.initData.listProvider);

    const searchTypeLocationStatus = useSelector(state => state.booking.searchTypeLocationStatus);
    const searchTypeLocationResult = useSelector(state => state.booking.searchTypeLocationResult);

    const searchUsersProviderStatus = useSelector(state => state.booking.searchUsersProviderStatus);
    const searchUsersProviderResult = useSelector(state => state.booking.searchUsersProviderResult);

    let [busySelecting, setBusySelecting] = useState(false);
    let [selectedDays, setSelectedDays] = useState(null);
    let [visiblePending, setVisiblePending] = useState(false);
    let [customDuration, setCustomDuration] = useState('');

    const [form] = Form.useForm();
    const [formAuto] = Form.useForm();
    const [formApp] = Form.useForm();
    const [formSearch] = Form.useForm();

    const DogovorSobstvennost = {
        'собственность': '',
        'совместная собственность супругов': '',
        'аренда': 'Договор аренды',
        'лизинг': 'Договор лизинга',
        'безвозмездное пользование': 'Договор безвозмездного пользования'
    }

    const typeSobstvennost = {
        'собственность': '1 - ',
        'совместная собственность супругов': '2 - ',
        'аренда': '3 - ',
        'лизинг': '4 - ',
        'безвозмездное пользование': '5 - '
    }

    const [api, contextHolder] = notification.useNotification();

    const [breadcrumb, setBreadcrumb] = React.useState([
        {name: 'Сервисы', link: `/`},
        {name: 'Dock booking', link: `/dockBooking`}
    ]);

    const openNotificationWithIcon = (type, description, message) => {
        if (message) {
            api[type]({
                message: message,
                description
            });
        } else {
            api[type]({
                message: type == 'error' ? 'Ошибка' : 'Успешно',
                description
            });
        }
    };

    const handleFilter = (type, dateFilter, warehouseID) => {
        if (statusInitData == 'SUCCESS' && statusBooking == 'IDLE') {

            setSelectSklad(null);
            setSelectBooking(null);
            setFilterDirectionDock(directionDock);
            setLoading(true);

            if (type && type == 'reset') {

                setCheckedsSklads([]);
                setDirectionDock([]);
                setDateBookings(dayjs());
                setDateBookingsSelect(dayjs());

                dispatch(getListBooking({
                    idWarehouse: null,
                    directionDock: null,
                    dateBookings: dayjs().format('DD.MM.YYYY'),
                    timeList,
                    infoWarehouses,
                    dataUser
                }));

            } else if (type && type == 'date') {

                // setCheckedsSklads([]);
                setDirectionDock([]);
                setDateBookings(dayjs(dateFilter));
                setDateBookingsSelect(dayjs(dateFilter));

                dispatch(getListBooking({
                    idWarehouse: checkedSklads.indexOf('*') < 0 && checkedSklads || null,
                    directionDock,
                    dateBookings: dateFilter.format('DD.MM.YYYY'),
                    timeList,
                    infoWarehouses,
                    dataUser
                }));

            } else if (type && type == 'search') {

                setDirectionDock([]);
                setDateBookings(dayjs(dateFilter));
                setDateBookingsSelect(dayjs(dateFilter));

                dispatch(getListBooking({
                    idWarehouse: [warehouseID],
                    directionDock: null,
                    dateBookings: dayjs(dateFilter).format('DD.MM.YYYY'),
                    timeList,
                    infoWarehouses,
                    dataUser
                }));

            } else {

                dispatch(getListBooking({
                    idWarehouse: checkedSklads.indexOf('*') < 0 && checkedSklads || null,
                    directionDock,
                    dateBookings: dateBookings && dateBookings.format('DD.MM.YYYY') || dayjs().format('DD.MM.YYYY'),
                    timeList,
                    infoWarehouses,
                    dataUser
                }));

            }

        }
    }

    const handleBlockSelectCells = ({warehouseID}) => {

        // console.log();
        let blockedsDock = {};

        if (selectedDays[warehouseID] && Object.keys(selectedDays[warehouseID]) && Object.keys(selectedDays[warehouseID]).length > 0) {
            Object.keys(selectedDays[warehouseID]).map((dockId, index) => {
                blockedsDock = {
                    ...blockedsDock,
                    [dockId]: {
                        'ELEMENT_CODE': 'element_' + Date.now() + index,
                        'FIELDS': {
                            'NAME': 'Blocked',
                            'PROPERTY_295': dateBookings.format('DD.MM.YYYY'),
                            'PROPERTY_297': dockId,
                            'PROPERTY_303': selectedDays[warehouseID][dockId].times,
                            'PROPERTY_475': '1'
                        }
                    }
                }
            })
        }

        dispatch(addBlockedCell({
            docks: blockedsDock,
            dateBookings: dateBookings.format('DD.MM.YYYY')
        }));

    }

    const handleBlockAll = (warehouseID) => {

        let warehouse = infoWarehouses[warehouseID];

        let {docks, timeWorkList} = warehouse;

        let blockedsDock = {};

        docks.map((dock, index) => {

            let timeList = [];

            timeWorkList.map(timeID => {

                let error = false;

                if (blockedCells[dock.ID] && blockedCells[dock.ID].indexOf(timeID) >= 0) {
                    error = true;
                }

                if (!error && reservedCells[dock.ID] && reservedCells[dock.ID].findIndex(el => el.rangeTime.indexOf(timeID) >= 0) >= 0) {
                    error = true;
                }

                if (!error) {
                    timeList.push(timeID);
                }

            })

            blockedsDock = {
                ...blockedsDock,
                [dock.ID]: {
                    'ELEMENT_CODE': 'element_' + Date.now() + index,
                    'FIELDS': {
                        'NAME': 'Blocked',
                        'PROPERTY_295': dateBookings.format('DD.MM.YYYY'),
                        'PROPERTY_297': dock.ID,
                        'PROPERTY_303': timeList,
                        'PROPERTY_475': '1'
                    }
                }
            }

        })

        dispatch(addBlockedCell({
            docks: blockedsDock,
            dateBookings: dateBookings.format('DD.MM.YYYY')
        }));

    }

    useEffect(() => {
        if (searchUsersProviderStatus == 'SUCCESS') {
            dispatch(searchUsersProviderIdle());

            setDataSearchUsersProvider(searchUsersProviderResult.selectOptions);

        }
    }, [searchUsersProviderStatus])

    useEffect(() => {
        if (searchTypeLocationStatus == 'SUCCESS') {
            dispatch(searchTypeLocationIdle());

            setDataSearchTypeLocation(searchTypeLocationResult.selectOptions);

        }
    }, [searchTypeLocationStatus])

    useEffect(() => {
        if (searchBookingStatus == 'SUCCESS') {
            dispatch(searchBookingIdle());
            if (searchBookingResult && searchBookingResult.result && searchBookingResult.result.length == 1) {

                // console.log(searchBookingResult);

                let dateItem = Object.values(searchBookingResult.result[0][fields["PROPERTY_621"].code])[0];

                let warehouseID = Object.values(searchBookingResult.result[0][fields["PROPERTY_291"].code])[0];

                // console.log(warehouseID);

                let dateItemArray = dateItem.split('.');

                handleFilter('search', dateItemArray[1] + '.' + dateItemArray[0] + '.' + dateItemArray[2], warehouseID);

            }
        }
    }, [searchBookingStatus])

    // useEffect(() => {
    //     let options = [];
    //     if(activeWarehouses && activeWarehouses.length > 0){
    //         activeWarehouses.map(id => {
    //             let item = infoWarehouses[id];
    //             options.push({
    //                 label: item.NAME,
    //                 value: item.ID
    //             })
    //         })
    //     }
    //     setActiveWarehousesOptions(options);
    // }, [activeWarehouses])

    useEffect(() => {
        if (getBookingShipmentStatus == 'SUCCESS') {
            dispatch(getBookingShipmentIdle());
        }
    }, [getBookingShipmentStatus])

    useEffect(() => {
        if (addBookingStatus == 'SUCCESS' && addBookingResult.result) {
            dispatch(addBookingIdle());

            setIsModalAppointmentOpen(false);
            setIsModalTimesOpen(true);

            form.resetFields();

            setShowSelectDock({
                warehouse: addBookingResult.result[0][fields["PROPERTY_291"].code] && infoWarehouses[Object.values(addBookingResult.result[0][fields["PROPERTY_291"].code])[0]],
                dateBookings: addBookingResult.result[0][fields["PROPERTY_621"].code],
                duration: addBookingResult.result[0][fields["PROPERTY_317"].code] && Object.values(addBookingResult.result[0][fields["PROPERTY_317"].code])[0],
                booking: {
                    ...addBookingResult.result[0],
                    defaultDuration: addBookingResult.result[0][fields["PROPERTY_317"].code] && Object.values(addBookingResult.result[0][fields["PROPERTY_317"].code])[0]
                },
                timeList,
                method: 'add'
            });

            setDragBooking(addBookingResult.result[0]);

            // dispatch(getFreeCells(showSelectDock));

            // setIsModalAppointmentOpen(false);
            handleFilter();


            openNotificationWithIcon('success', 'Рейс добавлен');
        }
    }, [addBookingStatus])

    useEffect(() => {
        if (showSelectDock && showSelectDock.booking && statusGetFreeCells == 'IDLE' && setIsModalTimesOpen) {
            if (dateBookingsSelect) {
                dispatch(getFreeCells({
                    ...showSelectDock,
                    dateBookings: dateBookingsSelect.format('DD.MM.YYYY'),
                    timeList
                }));
            }
        }
    }, [dateBookingsSelect, showSelectDock])

    useEffect(() => {
        if (deleteBookingStatus == 'SUCCESS') {
            handleFilter();
            dispatch(deleteBookingIdle());

            openNotificationWithIcon('success', 'Рейс удален');
        }
    }, [deleteBookingStatus])

    useEffect(() => {
        if (statusGetFreeCells == 'SUCCESS') {
            dispatch(getFreeCellsIdle());
        }
    }, [statusGetFreeCells])

    useEffect(() => {
        if (addBlockedCellStatus == 'SUCCESS') {
            handleFilter();
            dispatch(addBlockedCellIdle());
        }
    }, [addBlockedCellStatus])

    useEffect(() => {
        if (updateBookingStatus == 'SUCCESS') {

            setDragBooking(null);
            setDragWarehouse(null);
            setDragTimes(null);
            setDragDock(null);
            setShowSelectDock(null);
            setIsModalTimesOpen(false);
            setIsModalAutoOpen(false);
            setVisibleDopFieldsPricep(false);
            formAuto.resetFields();
            setIsModalEditAppOpen(false);
            setDefaultBookingInfo(null);
            setCustomDuration('');

            handleFilter();

            dispatch(updateBookingIdle());
            dispatch(getFreeCellsIdle());
            dispatch(getFreeCellsClear());

            openNotificationWithIcon('success', 'Рейс обновлен');

        }
    }, [updateBookingStatus])

    useEffect(() => {

        if (statusCheckedBlockedCellsDate == 'SUCCESS') {

            setDateBookingsSelect(dateBookings);

            dispatch(checkedBlockedCellsDateIdle());

            if (resultCheckedBlockedCellsDate) {

                let status = fields['PROPERTY_311'].options['367'].code;

                if (equipmentsInfo) {
                    if (infoDocks[dragDock].PROPERTY_337 && dragBooking[fields["PROPERTY_339"].code]) {
                        if (infoDocks[dragDock].PROPERTY_337 && Object.values(infoDocks[dragDock].PROPERTY_337).length) {
                            if (equipmentsInfo[Object.values(infoDocks[dragDock].PROPERTY_337)[0]]) {
                                if (equipmentsInfo[Object.values(infoDocks[dragDock].PROPERTY_337)[0]].PROPERTY_333) {
                                    if (Object.values(equipmentsInfo[Object.values(infoDocks[dragDock].PROPERTY_337)[0]].PROPERTY_333).indexOf(Object.values(dragBooking[[fields["PROPERTY_339"].code]])[0]) < 0) {
                                        status = fields['PROPERTY_311'].options['369'].code;
                                    }
                                }
                            }
                        }
                    }
                }

                if (infoDocks[dragDock].PROPERTY_273 && dragBooking[fields["PROPERTY_385"].code]) {

                    if (Object.values(dragBooking[fields["PROPERTY_385"].code])[0] == fields['PROPERTY_385'].options['401'].code) { // Pickup

                        if (Object.values(infoDocks[dragDock].PROPERTY_273).indexOf('165') < 0) {
                            status = fields['PROPERTY_311'].options['369'].code;
                        }

                    }
                    if (Object.values(dragBooking[fields["PROPERTY_385"].code])[0] == fields['PROPERTY_385'].options['399'].code) {

                        if (Object.values(infoDocks[dragDock].PROPERTY_273).indexOf('163') < 0) {
                            status = fields['PROPERTY_311'].options['369'].code;
                        }

                    }

                }

                if ((status == fields['PROPERTY_311'].options['369'].code && dataUser.group == 'user_ul') || status != fields['PROPERTY_311'].options['369'].code) {

                    dispatch(updateBooking({
                        'ELEMENT_ID': dragBooking.ID,
                        'FIELDS': {
                            ...dragBooking,
                            [fields["PROPERTY_281"].code]: dateBookingsSelect.format('DD.MM.YYYY'),
                            [fields["PROPERTY_621"].code]: dateBookingsSelect.format('DD.MM.YYYY'),
                            [fields["PROPERTY_285"].code]: dragDock,
                            [fields["PROPERTY_323"].code]: dragTimes[0],
                            [fields["PROPERTY_325"].code]: dragTimes[dragTimes.length - 1],
                            [fields["PROPERTY_311"].code]: status,
                            [fields["PROPERTY_387"].code]: dragBooking[fields["PROPERTY_387"].code] && Object.values(dragBooking[fields["PROPERTY_387"].code])[0] == fields['PROPERTY_387'].options['405'].id && fields['PROPERTY_387'].options['403'].id || Object.values(dragBooking[fields["PROPERTY_387"].code])[0]
                        }
                    }));
                } else {
                    if (status == fields['PROPERTY_311'].options['369'].code) {
                        openNotificationWithIcon('error', 'Ошибка при бронировании ячейки');
                    }
                }

            } else {
                handleFilter();
                setShowSelectDock(null);
                setIsModalTimesOpen(false);
                openNotificationWithIcon('error', 'Ячейка заблокирована');
            }
        }

    }, [statusCheckedBlockedCellsDate])

    useEffect(() => {

        if (statusCheckedBlockedCells == 'SUCCESS') {

            setDateBookingsSelect(dateBookings);

            dispatch(checkedBlockedCellsIdle());

            if (resultCheckedBlockedCells) {

                let status = fields['PROPERTY_311'].options['367'].code;

                if (equipmentsInfo) {
                    if (infoDocks[dragDock.ID].PROPERTY_337 && dragBooking[[fields["PROPERTY_339"].code]] && Object.values(infoDocks[dragDock.ID].PROPERTY_337)[0] != '703') {
                        if (Object.values(infoDocks[dragDock.ID].PROPERTY_337).length) {
                            if (equipmentsInfo[Object.values(infoDocks[dragDock.ID].PROPERTY_337)[0]]) {
                                if (equipmentsInfo[Object.values(infoDocks[dragDock.ID].PROPERTY_337)[0]].PROPERTY_333) {
                                    if (Object.values(equipmentsInfo[Object.values(infoDocks[dragDock.ID].PROPERTY_337)[0]].PROPERTY_333).indexOf(Object.values(dragBooking[[fields["PROPERTY_339"].code]])[0]) < 0) {
                                        status = fields['PROPERTY_311'].options['369'].code;
                                        // console.log(2);
                                    }
                                }
                            }
                        }
                    }
                }

                if (infoDocks[dragDock.ID].PROPERTY_273 && dragBooking[fields["PROPERTY_385"].code]) {

                    if (Object.values(dragBooking[fields["PROPERTY_385"].code])[0] == fields['PROPERTY_385'].options['401'].code) { // Pickup

                        if (Object.values(infoDocks[dragDock.ID].PROPERTY_273).indexOf('165') < 0) {
                            status = fields['PROPERTY_311'].options['369'].code;
                            // console.log(2);
                        }

                    }
                    if (Object.values(dragBooking[fields["PROPERTY_385"].code])[0] == fields['PROPERTY_385'].options['399'].code) { // Drop

                        if (Object.values(infoDocks[dragDock.ID].PROPERTY_273).indexOf('163') < 0) {
                            status = fields['PROPERTY_311'].options['369'].code;
                            // console.log(3);
                        }

                    }

                }

                if ((status == fields['PROPERTY_311'].options['369'].code && dataUser.group == 'user_ul') || status != fields['PROPERTY_311'].options['369'].code) {

                    // console.log(dragBooking);

                    dispatch(updateBooking({
                        'ELEMENT_ID': dragBooking.ID,
                        'FIELDS': {
                            ...dragBooking,
                            [fields["PROPERTY_281"].code]: dateBookings.format('DD.MM.YYYY'),
                            [fields["PROPERTY_621"].code]: dateBookings.format('DD.MM.YYYY'),
                            [fields["PROPERTY_285"].code]: dragDock.ID,
                            [fields["PROPERTY_323"].code]: dragTimes[0],
                            [fields["PROPERTY_325"].code]: dragTimes[dragTimes.length - 1],
                            [fields["PROPERTY_311"].code]: status
                        }
                    }));
                } else {
                    if (status == fields['PROPERTY_311'].options['369'].code) {
                        openNotificationWithIcon('error', 'Ошибка при бронировании ячейки');
                    }
                }

            } else {
                handleFilter();
                setShowSelectDock(null);
                setIsModalTimesOpen(false);
                openNotificationWithIcon('error', 'Ячейка заблокирована');
            }
        }

    }, [statusCheckedBlockedCells])

    useEffect(() => {

        if (statusInitData == 'SUCCESS' && isSelectWarehouse) {
            handleFilter();
        }

        if (statusInitData == 'SUCCESS') {
            let options = [{
                label: 'Все склады',
                value: '*'
            }];
            if (infoWarehouses && Object.keys(infoWarehouses).length > 0) {
                Object.keys(infoWarehouses).map(id => {
                    let item = infoWarehouses[id];
                    options.push({
                        label: item.NAME,
                        value: item.ID
                    })
                })
            }
            setActiveWarehousesOptions(options);
        }

    }, [statusInitData])

    useEffect(() => {
        if (statusBooking == 'SUCCESS') {

            dispatch(getListBookingIdle());
            setSelectedDays(null);


            if (searchBookingResult && searchBookingResult.result && searchBookingResult.result.length == 1) {

                if (searchBookingResult.result[0][fields["PROPERTY_291"].code]) {

                    let warehouseID = Object.values(searchBookingResult.result[0][fields["PROPERTY_291"].code])[0];

                    if (document.getElementById('warehouse' + warehouseID)) {
                        window.scroll({
                            behavior: 'smooth',
                            top: document.getElementById('warehouse' + warehouseID).getBoundingClientRect().top + window.scrollY - document.querySelector('.dock-booking__filters').clientHeight
                        })
                    }

                }

            } else if (searchBookingResult && searchBookingResult.result && searchBookingResult.result.length > 1 && searchWarehouse) {

                let warehouseID = searchWarehouse;

                if (document.getElementById('warehouse' + warehouseID)) {

                    window.scroll({
                        behavior: 'smooth',
                        top: document.getElementById('warehouse' + warehouseID).getBoundingClientRect().top + window.scrollY - document.querySelector('.dock-booking__filters').clientHeight
                    })

                }

            }

        }
        if (statusBooking !== 'LOADING') {
            setLoading(false);
        }
    }, [statusBooking])

    useEffect(() => {
        if (statusUpdateBlocked == 'SUCCESS') {
            handleFilter();
            dispatch(updateBlockedIdle());
        }
    }, [statusUpdateBlocked])

    const cellMouseDown = (e, timeID, dockID, warehouseID) => {

        if (e.target.classList.contains('_blocked')) {
            setStateCellMouseDown('blocked');
        }
        if (e.target.classList.contains('_default')) {
            setStateCellMouseDown('unblocked');
        }

        if (e.target.classList.contains('_blocked') || e.target.classList.contains('_default')) {
            setSelectedDays(null);
            setSelectedDays({
                [warehouseID]: {
                    [dockID]: {
                        times: [timeID]
                    }
                }
            });
            setBusySelecting(true);
        }
    }

    const cellMouseOver = (e, timeID, dockID, warehouseID) => {
        if (busySelecting && (e.target.classList.contains('_blocked') || e.target.classList.contains('_default'))) {

            let dataCell;

            if (selectedDays[warehouseID]) {
                if (selectedDays[warehouseID][dockID]) {
                    dataCell = {
                        ...selectedDays,
                        [warehouseID]: {
                            ...selectedDays[warehouseID],
                            [dockID]: {
                                times: [
                                    ...selectedDays[warehouseID][dockID].times,
                                    timeID
                                ]
                            }
                        }
                    }
                } else {
                    dataCell = {
                        ...selectedDays,
                        [warehouseID]: {
                            ...selectedDays[warehouseID],
                            [dockID]: {
                                times: [
                                    timeID
                                ]
                            }
                        }
                    }
                }
            } else {
                dataCell = {
                    ...selectedDays,
                    [warehouseID]: {
                        [dockID]: {
                            times: [timeID]
                        }
                    }
                }
            }

            setSelectedDays(dataCell);

            setSelectedDays(dataCell);

        }
    }

    useEffect(() => {
        const handleDocumentMouseUp = event => {

            setBusySelecting(false);

        };

        document.addEventListener('mouseup', handleDocumentMouseUp);
        return () => {
            document.removeEventListener('mouseup', handleDocumentMouseUp);
        };
    }, [selectedDays]);

    return (
        <>
            {contextHolder}
            <main className="main">
                <div className="main__content container">
                    <div className="main__wrap">
                        <BreadCrumbs breadcrumb={breadcrumb}/>
                        {view == 'export' && (
                            <a onClick={e => {
                                e.preventDefault();

                                setView('warehouses');

                            }} className="btn" href="#">Вернуться</a>
                        ) || (
                            <a onClick={e => {
                                e.preventDefault();

                                setView('export');

                            }} className="btn" href="#">Выгрузить в Excel</a>
                        )}
                    </div>

                    <div className="dock-booking">
                        <h1 className="section-title dock-booking__section-title">Dock booking {loading && <Spin/>}</h1>

                        {(statusInitData !== 'SUCCESS' || loading) && (
                            <Alert style={{marginTop: 20}} type='info'
                                   message="Данные загружаются. Пожалуйста дождитесь загрузки и не обновляйте страницу."/>
                        )}

                        {view == 'export' && <ExportForm setView={setView}/>}

                        {view == 'warehouses' && statusInitData == 'SUCCESS' && infoWarehouses && (
                            <>
                                <div className="filters dock-booking__filters">
                                    <div className="filters__row" style={{alignItems: 'flex-start'}}>
                                        <div className="filters__col">
                                            {infoWarehouses && Object.keys(infoWarehouses).length > 0 && (
                                                <Select
                                                    size="large"
                                                    mode="multiple"
                                                    allowClear
                                                    style={{
                                                        width: 200,
                                                    }}
                                                    showSearch
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    value={checkedSklads}
                                                    placeholder="Выберите склад"
                                                    onChange={e => {
                                                        // console.log(e);
                                                        setCheckedsSklads(e);
                                                        if (!isSelectWarehouse && e && e.length > 0) {
                                                            setIsSelectWarehouse(true);
                                                        } else if (!e.length) {
                                                            setIsSelectWarehouse(false);
                                                        }
                                                    }}
                                                    options={activeWarehousesOptions}
                                                />
                                            )}
                                        </div>
                                        <div className="filters__col">
                                            {infoWarehouses && Object.keys(infoWarehouses).length > 0 && (
                                                <Select
                                                    size="large"
                                                    mode="multiple"
                                                    allowClear
                                                    style={{
                                                        width: 200,
                                                    }}
                                                    value={directionDock}
                                                    placeholder="Выберите тип"
                                                    onChange={e => {
                                                        setDirectionDock(e)
                                                    }}
                                                    options={[
                                                        {
                                                            value: '163',
                                                            label: 'INBOUND'
                                                        }, {
                                                            value: '165',
                                                            label: 'OUTBOUND'
                                                        }
                                                    ]}
                                                />
                                            )}
                                        </div>
                                        <div className="filters__col">
                                            <Space.Compact block size='large' style={{marginBottom: 3}}>
                                                <Button size="large" onClick={e => {
                                                    // setDateBookings(  );

                                                    handleFilter('date', dateBookings.add(-1, 'day'));

                                                }} type="primary" icon={<LeftOutlined/>}/>
                                                <DatePicker
                                                    locale={locale}
                                                    size="large"
                                                    defaultValue={dateBookings}
                                                    format={'DD.MM.YYYY'}
                                                    value={dateBookings}
                                                    onChange={(date, dateString) => {
                                                        if (date) {
                                                            setDateBookings(date);
                                                            setDateBookingsSelect(date);
                                                        }
                                                        // setCheckedsSklads([]);
                                                        setDirectionDock([]);
                                                    }}
                                                />
                                                <Button size="large" onClick={e => {
                                                    // setDateBookings( dateBookings.add(1, 'day') )
                                                    handleFilter('date', dateBookings.add(1, 'day'));
                                                }} type="primary" icon={<RightOutlined/>}/>
                                            </Space.Compact>
                                            <Space.Compact block>
                                                {[{
                                                    title: 'ПН', i: 0, order: 2
                                                }, {
                                                    title: 'ВТ', i: 1, order: 3
                                                }, {
                                                    title: 'СР', i: 2, order: 4
                                                }, {
                                                    title: 'ЧТ', i: 3, order: 5
                                                }, {
                                                    title: 'ПТ', i: 4, order: 6
                                                }, {
                                                    title: 'СБ', i: 5, order: 7
                                                }, {
                                                    title: 'ВС', i: 6, order: 8
                                                }].map((item, key) => {
                                                    return (
                                                        <Button
                                                            data-day={item.i - dateBookings.weekday()}
                                                            data-current={dateBookings.weekday()}
                                                            onClick={e => {
                                                                handleFilter('date', dateBookings.add(item.i - dateBookings.weekday(), 'day'));
                                                                // setDateBookings( dateBookings.add(key - dateBookings.day(), 'day') )
                                                            }}
                                                            style={{
                                                                // order: item.order,
                                                                padding: 0,
                                                                width: '100%'
                                                            }} size={'small'}
                                                            type={item.i == dateBookings.weekday() && 'primary' || 'default'}
                                                        >{item.title}</Button>
                                                    )
                                                })}
                                            </Space.Compact>
                                        </div>
                                        <div className="filters__col filters__col--mla">
                                            {loading && <Spin style={{marginRight: 5}}/>}
                                            <button
                                                className="btn btn--blue"
                                                onClick={e => {

                                                    // dispatch(searchBookingReset()); 2
                                                    handleFilter();

                                                }}>Применить
                                            </button>
                                        </div>
                                        <div className="filters__col">
                                            <button className="btn" onClick={e => {

                                                // dispatch(searchBookingReset());
                                                handleFilter('reset');

                                            }}>Сбросить фильтр
                                            </button>
                                        </div>
                                        <div className="filters__col" style={{marginLeft: 'auto'}}>
                                            <Space size={8}>
                                                {responseBooking && Object.keys(responseBooking).length > 0 && (
                                                    <span>
                                                        Отображать распределенные <Switch value={visiblePending}
                                                                                          onChange={e => {
                                                                                              setVisiblePending(!visiblePending);
                                                                                          }}/>
                                                    </span>
                                                )}
                                                {typeUser && typeUser == 'isUniliver' && (

                                                    <a className="btn btn--blue" href="#" onClick={e => {
                                                        e.preventDefault();

                                                        setIsModalAppointmentOpen(true);

                                                    }}><PlusOutlined color={'inherit'}
                                                                     style={{marginRight: 6}}/> Appointment</a>

                                                )}
                                            </Space>
                                        </div>
                                    </div>
                                    <div className='filters__row'>

                                        <Search
                                            setIsSelectWarehouse={setIsSelectWarehouse}
                                            setSearchWarehouse={setSearchWarehouse}
                                            formSearch={formSearch}
                                            checkedSklads={checkedSklads}
                                            activeWarehouses={activeWarehouses}
                                            activeWarehousesOptions={activeWarehousesOptions}
                                        />

                                        {searchBookingResult && searchBookingResult.result && !searchBookingResult.result.length && (
                                            <Alert type='info' message="Данный рейс не найден"/>
                                        )}

                                        {searchBookingResult && searchBookingResult.result && searchBookingResult.result.length > 1 && searchBookingResult.result.map(item => {

                                            let warehouseName = '';

                                            if (item[fields["PROPERTY_291"].code]) {
                                                warehouseName = infoWarehouses[Object.values(item[fields["PROPERTY_291"].code])[0]].NAME;
                                            }

                                            return (

                                                <Button onClick={e => {

                                                    let dateItem = Object.values(item[fields["PROPERTY_621"].code])[0];

                                                    let dateItemArray = dateItem.split('.');

                                                    handleFilter('search', dateItemArray[1] + '.' + dateItemArray[0] + '.' + dateItemArray[2]);

                                                    let warehouseID = Object.values(item[fields["PROPERTY_291"].code])[0];

                                                    setSearchWarehouse(warehouseID);

                                                }}>{item.NAME}</Button>

                                            )

                                        })}

                                    </div>
                                </div>
                                {isSelectWarehouse && responseBooking && Object.keys(responseBooking).length > 0 && (
                                    <>
                                        {responseBooking && Object.keys(responseBooking).length > 0 && Object.keys(responseBooking).map((warehouseID) => {

                                            let bookings = responseBooking[warehouseID];

                                            let warehouse = infoWarehouses[warehouseID];

                                            if (!warehouse) {
                                                return;
                                            }

                                            let {docks} = warehouse;

                                            let countBlocked = 0;
                                            let countConfirmed = 0;
                                            let countPending = 0;
                                            let countInfeasible = 0;

                                            if (warehouse.docks && warehouse.docks.length > 0) {

                                                warehouse.docks.map(dock => {
                                                    if (blockedCells[dock.ID] && blockedCells[dock.ID].length > 0) {
                                                        countBlocked = countBlocked + blockedCells[dock.ID].length;
                                                    }
                                                    if (reservedCells[dock.ID]) {
                                                        reservedCells[dock.ID].map(item => {
                                                            if (item.status == fields['PROPERTY_311'].options['371'].code) {
                                                                if (warehouse && warehouse.PROPERTY_315 && Object.values(warehouse.PROPERTY_315)[0] == 373) {
                                                                    if (item.rangeTimeHour && item.rangeTimeHour.length > 0) {
                                                                        countConfirmed = countConfirmed + item.rangeTimeHour.length;
                                                                    }
                                                                } else {
                                                                    if (item.rangeTime && item.rangeTime.length > 0) {
                                                                        countConfirmed = countConfirmed + item.rangeTime.length;
                                                                    }
                                                                }
                                                            } else if (item.status == fields['PROPERTY_311'].options['367'].code) {
                                                                if (warehouse && warehouse.PROPERTY_315 && Object.values(warehouse.PROPERTY_315)[0] == 373) {
                                                                    if (item.rangeTimeHour && item.rangeTimeHour.length > 0) {
                                                                        countPending = countPending + item.rangeTimeHour.length;
                                                                    }
                                                                } else {
                                                                    if (item.rangeTime && item.rangeTime.length > 0) {
                                                                        countPending = countPending + item.rangeTime.length;
                                                                    }
                                                                }
                                                            } else if (item.status == fields['PROPERTY_311'].options['369'].code) {
                                                                if (warehouse && warehouse.PROPERTY_315 && Object.values(warehouse.PROPERTY_315)[0] == 373) {
                                                                    if (item.rangeTimeHour && item.rangeTimeHour.length > 0) {
                                                                        countInfeasible = countInfeasible + item.rangeTimeHour.length;
                                                                    }
                                                                } else {
                                                                    if (item.rangeTime && item.rangeTime.length > 0) {
                                                                        countInfeasible = countInfeasible + item.rangeTime.length;
                                                                    }
                                                                }
                                                            }
                                                        })
                                                    }
                                                })

                                            }

                                            return (
                                                <div className="dock-booking__content" id={'warehouse' + warehouseID}>
                                                    {/* Sidebar start */}
                                                    <div className="dock-booking__sidebar">
                                                        <div className="block-tabs dock-booking__block-tabs">
                                                            <div className="block-tabs__tabs">
                                                                <div className="block-tabs__items">
                                                                    <a onClick={e => {
                                                                        e.preventDefault();

                                                                        setShowDetailBooking(null);

                                                                    }}
                                                                       className={`block-tabs__item ${(!showDetailBooking || !showDetailWarehouse || (showDetailWarehouse && showDetailWarehouse !== warehouseID)) && 'active' || ''}`}
                                                                       href="#" data-id="1">Shipment</a>
                                                                    <a onClick={e => {
                                                                        e.preventDefault();

                                                                        setShowDetailBooking(true);
                                                                        setShowDetailWarehouse(warehouseID);

                                                                    }}
                                                                       className={`block-tabs__item ${showDetailBooking && showDetailWarehouse == warehouseID && 'active' || ''}`}
                                                                       href="#" data-id="2">Details</a>
                                                                </div>
                                                            </div>
                                                            <div className="block-tabs__body">
                                                                <div
                                                                    className={`block-tabs__block ${(!showDetailBooking || !showDetailWarehouse || (showDetailWarehouse && showDetailWarehouse !== warehouseID)) && 'active' || ''}`}>

                                                                    <div
                                                                        className="filters-cards block-tabs__filters-cards">

                                                                        <div style={{marginBottom: 40}}>
                                                                            <div className="filters-cards__list"
                                                                                 style={{marginBottom: 20}}>
                                                                                <Space
                                                                                    style={{justifyContent: 'space-between'}}>
                                                                                    <b>{warehouse.NAME}</b>
                                                                                    {dataUser && dataUser.group && dataUser.group == 'user_ul' && (
                                                                                        <Button style={{padding: 0}}
                                                                                                type="link" size='small'
                                                                                                onClick={e => {

                                                                                                    if (addBlockedCellStatus == 'IDLE') {
                                                                                                        handleBlockAll(warehouseID);
                                                                                                    }

                                                                                                }}>Block All</Button>
                                                                                    )}
                                                                                </Space>
                                                                                {bookings && bookings.length > 0 && bookings.map(booking => {
                                                                                    const {NAME} = booking;

                                                                                    if (booking && booking[fields["PROPERTY_311"].code] && !visiblePending) {
                                                                                        return;
                                                                                    }

                                                                                    if (dataUser && dataUser.group && dataUser.group !== 'user_view' && dataUser.group !== 'user_ul' && booking && getValueProperty(booking, fields["PROPERTY_289"].id) !== dataUser.group) {
                                                                                        return;
                                                                                    }

                                                                                    return (
                                                                                        <div
                                                                                            className="filters-cards__item">
                                                                                            <div
                                                                                                className="filters-cards__item-row">
                                                                                                <div
                                                                                                    className="filters-cards__item-col _name">
                                                                                                    <Button
                                                                                                        title={getValueProperty(booking, fields["PROPERTY_341"].id)}
                                                                                                        style={{
                                                                                                            padding: 0,
                                                                                                            height: 'auto'
                                                                                                        }}
                                                                                                        type={'link'}
                                                                                                        onClick={e => {
                                                                                                            setDetailBooking(booking);
                                                                                                            setShowDetailBooking(true);
                                                                                                            setShowDetailWarehouse(warehouseID);
                                                                                                            // if(!booking.shortName){
                                                                                                            //     dispatch(getServprovName({
                                                                                                            //         booking
                                                                                                            //     }));
                                                                                                            // }
                                                                                                        }}
                                                                                                        className="filters-cards__item-number _number">{getValueProperty(booking, fields["PROPERTY_341"].id)}</Button>
                                                                                                    <span
                                                                                                        className="filters-cards__item-text">Duration: {getValueProperty(booking, fields["PROPERTY_419"].id) != '' && getValueProperty(booking, fields["PROPERTY_419"].id) || getValueProperty(booking, fields["PROPERTY_317"].id)}</span>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="filters-cards__item-col">
                                                                                                    <div
                                                                                                        className="filters-cards__item-text-wrap">
                                                                                                        <span
                                                                                                            className="filters-cards__item-text"
                                                                                                            style={{
                                                                                                                fontWeight: 'bold',
                                                                                                                color: '#999'
                                                                                                            }}>{getValueProperty(booking, fields["PROPERTY_621"].id)}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                className="filters-cards__item-row">

                                                                                                {booking[fields["PROPERTY_377"].code] && dataUser && dataUser.group == 'user_ul' && (
                                                                                                    <div
                                                                                                        class="filters-cards__item-text-wrap">
                                                                                                        <span
                                                                                                            class="filters-cards__item-name">Carrier: </span>
                                                                                                        <span
                                                                                                            class="filters-cards__item-text">
                                                                                                            {listProvider && listProvider.listProviderInfo[Object.values(booking[fields["PROPERTY_377"].code])[0]] && listProvider.listProviderInfo[Object.values(booking[fields["PROPERTY_377"].code])[0]].NAME || ''}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )}

                                                                                            </div>
                                                                                            <div
                                                                                                className="filters-cards__item-row">

                                                                                                {booking[fields["PROPERTY_359"].code] && dataUser && dataUser.group == 'user_ul' && (
                                                                                                    <div
                                                                                                        class="filters-cards__item-text-wrap">
                                                                                                        <span
                                                                                                            class="filters-cards__item-name">City: </span>
                                                                                                        <span
                                                                                                            class="filters-cards__item-text">
                                                                                                            {getValueProperty(booking, fields["PROPERTY_359"].id)}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                )}

                                                                                            </div>

                                                                                            <div
                                                                                                className="filters-cards__item-row">
                                                                                                <div
                                                                                                    className="filters-cards__item-col"
                                                                                                    style={{maxWidth: '100%'}}>

                                                                                                    {(getValueProperty(booking, fields["PROPERTY_387"].id) == '' || getValueProperty(booking, fields["PROPERTY_387"].id) == fields['PROPERTY_387'].options['405'].code) && typeUser && typeUser != 'isView' && (
                                                                                                        <Button
                                                                                                            onClick={e => {

                                                                                                                if (updateBookingStatus == 'IDLE') {
                                                                                                                    dispatch(updateBooking({
                                                                                                                        'ELEMENT_ID': booking.ID,
                                                                                                                        'FIELDS': {
                                                                                                                            ...booking,
                                                                                                                            [fields["PROPERTY_387"].code]: fields['PROPERTY_387'].options['403'].code // Изменил поле "В работе" 403 - Y, 405 - N
                                                                                                                        },
                                                                                                                        'UPDATE_FIELDS': {
                                                                                                                            [fields["PROPERTY_387"].code]: fields['PROPERTY_387'].options['403'].code // Изменил поле "В работе" 403 - Y, 405 - N
                                                                                                                        }
                                                                                                                    }));
                                                                                                                }

                                                                                                            }}>Взять в
                                                                                                            работу</Button>
                                                                                                    )}

                                                                                                    {getValueProperty(booking, fields["PROPERTY_387"].id) == fields['PROPERTY_387'].options['403'].code && typeUser && typeUser != 'isView' && (
                                                                                                        <Space.Compact
                                                                                                            block>

                                                                                                            {(
                                                                                                                typeUser == 'isUniliver' ||
                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) == '' ||
                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) !== '' && getValueProperty(booking, fields["PROPERTY_311"].id) !== fields['PROPERTY_311'].options['371'].code
                                                                                                            ) && (
                                                                                                                <Button
                                                                                                                    draggable={(!booking[fields["PROPERTY_323"].code] || booking[fields["PROPERTY_323"].code] && getValueProperty(booking, fields["PROPERTY_323"].id) == '0') && true || false}

                                                                                                                    onDragStart={e => {

                                                                                                                        setDragBooking(booking);
                                                                                                                        setDragWarehouse(warehouse);

                                                                                                                    }}
                                                                                                                    onDragEnd={e => {

                                                                                                                        document.querySelectorAll('._drag-cell').forEach(item => item.classList.remove('_drag-cell'));

                                                                                                                    }}
                                                                                                                    shape="circle"
                                                                                                                    icon={
                                                                                                                        <DragOutlined/>}
                                                                                                                />
                                                                                                            )}
                                                                                                            {(
                                                                                                                typeUser == 'isUniliver' ||
                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) == '' ||
                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) !== '' && getValueProperty(booking, fields["PROPERTY_311"].id) !== fields['PROPERTY_311'].options['371'].code
                                                                                                            ) && (
                                                                                                                <Button
                                                                                                                    onClick={e => {

                                                                                                                        let duration = getValueProperty(booking, fields["PROPERTY_317"].id);


                                                                                                                        if (duration && duration.indexOf('.') >= 0 && f(duration) > 2) {
                                                                                                                            duration = duration[0] + '.' + duration[2] + '' + duration[3];
                                                                                                                        }

                                                                                                                        if (booking && getValueProperty(booking, fields["PROPERTY_419"].id) != '') {
                                                                                                                            duration = getValueProperty(booking, fields["PROPERTY_419"].id);
                                                                                                                        }

                                                                                                                        let durationFloat = duration.indexOf('.') >= 0 && duration.split('.').length > 1 && duration.split('.')[1] || false;

                                                                                                                        duration = Math.ceil(duration);

                                                                                                                        if (getValueProperty(warehouse, 315) == '375') {

                                                                                                                            if (durationFloat && durationFloat / 100 <= 0.5) {
                                                                                                                                duration = duration - 0.5;
                                                                                                                            }

                                                                                                                            duration = duration * 2;
                                                                                                                        }

                                                                                                                        setShowSelectDock({
                                                                                                                            warehouse,
                                                                                                                            dateBookings: dateBookingsSelect.format('DD.MM.YYYY'),
                                                                                                                            duration: duration,
                                                                                                                            booking,
                                                                                                                            method: booking[fields["PROPERTY_323"].code] && 'update' || 'add'
                                                                                                                        })
                                                                                                                        setIsModalTimesOpen(true);
                                                                                                                    }}
                                                                                                                    shape="circle"
                                                                                                                    icon={
                                                                                                                        <CalendarOutlined/>}/>
                                                                                                            )}
                                                                                                            {(
                                                                                                                typeUser == 'isUniliver' ||
                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) !== '' && getValueProperty(booking, fields["PROPERTY_311"].id) !== fields['PROPERTY_311'].options['371'].code
                                                                                                            ) && (
                                                                                                                <Button
                                                                                                                    onClick={e => {
                                                                                                                        if (updateBookingStatus == 'IDLE') {
                                                                                                                            dispatch(updateBooking({
                                                                                                                                'ELEMENT_ID': booking.ID,
                                                                                                                                'FIELDS': {
                                                                                                                                    ...booking,
                                                                                                                                    [fields["PROPERTY_285"].code]: null,
                                                                                                                                    [fields["PROPERTY_323"].code]: null,
                                                                                                                                    [fields["PROPERTY_325"].code]: null,
                                                                                                                                    [fields["PROPERTY_311"].code]: null,
                                                                                                                                    [fields["PROPERTY_621"].code]: getValueProperty(booking, fields["PROPERTY_439"].id),
                                                                                                                                }
                                                                                                                            }));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    shape="circle"
                                                                                                                    icon={
                                                                                                                        <BackwardOutlined/>}/>
                                                                                                            )}
                                                                                                            {(
                                                                                                                typeUser == 'isUniliver' &&
                                                                                                                getValueProperty(booking, fields["PROPERTY_311"].id) !== '' &&
                                                                                                                getValueProperty(booking, fields["PROPERTY_311"].id) !== fields['PROPERTY_311'].options['371'].code
                                                                                                            ) && (
                                                                                                                <Popconfirm
                                                                                                                    placement="top"
                                                                                                                    title={'Подтвердить рейс?'}
                                                                                                                    onConfirm={e => {
                                                                                                                        if (updateBookingStatus == 'IDLE') {
                                                                                                                            dispatch(updateBooking({
                                                                                                                                'ELEMENT_ID': booking.ID,
                                                                                                                                'FIELDS': {
                                                                                                                                    ...booking,
                                                                                                                                    [fields["PROPERTY_311"].code]: fields['PROPERTY_311'].options['371'].id
                                                                                                                                }
                                                                                                                            }));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    okText="Да"
                                                                                                                    cancelText="Нет"
                                                                                                                >
                                                                                                                    <Button
                                                                                                                        shape='circle'
                                                                                                                        icon={
                                                                                                                            <CheckOutlined
                                                                                                                                twoToneColor="#eb2f96"/>}/>
                                                                                                                </Popconfirm>
                                                                                                            )}
                                                                                                            {(typeUser == 'isUniliver' || typeUser == 'isCarrier') && getValueProperty(booking, fields["PROPERTY_311"].id) !== '' && (
                                                                                                                <Button
                                                                                                                    onClick={e => {

                                                                                                                        let dataForm = {};

                                                                                                                        if (booking) {
                                                                                                                            Object.keys(booking).map(key => {
                                                                                                                                if (booking[key] && Object.values(booking[key])) {
                                                                                                                                    dataForm = {
                                                                                                                                        ...dataForm,
                                                                                                                                        [key]: Object.values(booking[key])[0]
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    dataForm = {
                                                                                                                                        ...dataForm,
                                                                                                                                        [key]: booking[key]
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }

                                                                                                                        formAuto.setFieldsValue(dataForm);
                                                                                                                        setIsModalAutoOpen(true);
                                                                                                                        setDefaultBookingInfo(booking);

                                                                                                                    }}
                                                                                                                    shape="circle"
                                                                                                                    icon={
                                                                                                                        <CarOutlined/>}/>
                                                                                                            )}
                                                                                                            {typeUser == 'isUniliver' && (
                                                                                                                <Popconfirm
                                                                                                                    placement="top"
                                                                                                                    title={'Изменить кол-во слотов'}
                                                                                                                    description={
                                                                                                                        <Input
                                                                                                                            value={customDuration}
                                                                                                                            onChange={e => {
                                                                                                                                setCustomDuration(e.target.value);
                                                                                                                            }}/>}
                                                                                                                    onConfirm={e => {
                                                                                                                        if (updateBookingStatus == 'IDLE') {

                                                                                                                            let customDurationApp = customDuration;

                                                                                                                            if (getValueProperty(warehouse, 315) == '375') {
                                                                                                                                customDurationApp = customDurationApp / 2;
                                                                                                                            }

                                                                                                                            dispatch(updateBooking({
                                                                                                                                'ELEMENT_ID': booking.ID,
                                                                                                                                'FIELDS': {
                                                                                                                                    ...booking,
                                                                                                                                    [fields["PROPERTY_419"].code]: customDurationApp,
                                                                                                                                    [fields["PROPERTY_285"].code]: null,
                                                                                                                                    [fields["PROPERTY_311"].code]: null,
                                                                                                                                    [fields["PROPERTY_323"].code]: null,
                                                                                                                                    [fields["PROPERTY_325"].code]: null,
                                                                                                                                }
                                                                                                                            }));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    okText="Да"
                                                                                                                    cancelText="Нет"
                                                                                                                >
                                                                                                                    <Button
                                                                                                                        shape='circle'
                                                                                                                        icon={
                                                                                                                            <ClockCircleOutlined/>}/>
                                                                                                                </Popconfirm>
                                                                                                            )}
                                                                                                            {typeUser == 'isUniliver' && (
                                                                                                                <Popconfirm
                                                                                                                    placement="top"
                                                                                                                    title={'Вы хотите удалить рейс?'}
                                                                                                                    onConfirm={e => {
                                                                                                                        if (deleteBookingStatus == 'IDLE') {
                                                                                                                            dispatch(deleteBooking({
                                                                                                                                'ELEMENT_ID': booking.ID,
                                                                                                                                'CODE': booking.CODE,
                                                                                                                                'FIELDS': booking
                                                                                                                            }));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                    okText="Да"
                                                                                                                    cancelText="Нет"
                                                                                                                >
                                                                                                                    <Button
                                                                                                                        shape='circle'
                                                                                                                        icon={
                                                                                                                            <DeleteOutlined/>}/>
                                                                                                                </Popconfirm>
                                                                                                            )}
                                                                                                            {typeUser == 'isUniliver' && getValueProperty(booking, fields["PROPERTY_421"].id) == fields['PROPERTY_421'].options['417'].code && (
                                                                                                                <Button
                                                                                                                    onClick={e => {

                                                                                                                        let dataForm = {};

                                                                                                                        if (booking) {
                                                                                                                            Object.keys(booking).map(key => {
                                                                                                                                if (booking[key] && Object.values(booking[key])) {
                                                                                                                                    dataForm = {
                                                                                                                                        ...dataForm,
                                                                                                                                        [key]: key == 'NAME' && booking[key] || Object.values(booking[key])[0]
                                                                                                                                    }
                                                                                                                                } else {
                                                                                                                                    dataForm = {
                                                                                                                                        ...dataForm,
                                                                                                                                        [key]: booking[key]
                                                                                                                                    }
                                                                                                                                }
                                                                                                                            })
                                                                                                                        }

                                                                                                                        formApp.setFieldsValue(dataForm);
                                                                                                                        setIsModalEditAppOpen(true);
                                                                                                                        setDefaultBookingInfo(booking);

                                                                                                                    }}
                                                                                                                    shape="circle"
                                                                                                                    icon={
                                                                                                                        <EditOutlined/>}/>
                                                                                                            )}
                                                                                                        </Space.Compact>
                                                                                                    )}
                                                                                                </div>
                                                                                                <div
                                                                                                    className="filters-cards__item-col">
                                                                                                    {booking[fields["PROPERTY_385"].code] && (
                                                                                                        <span
                                                                                                            className="filters-cards__item-btn">
                                                                                                            {Object.values(booking[fields["PROPERTY_385"].code]) && Object.values(booking[fields["PROPERTY_385"].code]).length > 1 && Object.values(booking[fields["PROPERTY_385"].code]).map(item => {
                                                                                                                return item == fields['PROPERTY_385'].options['399'].id && 'D' || 'P'
                                                                                                            }).join(' ') || Object.values(booking[fields["PROPERTY_385"].code])[0] == fields['PROPERTY_385'].options['399'].code && 'Drop' || 'Pickup'}
                                                                                                        </span>
                                                                                                    )}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={`block-tabs__block ${showDetailBooking && showDetailWarehouse == warehouseID && 'active' || ''}`}>
                                                                    {detailBooking && typeTrucks && (
                                                                        <>
                                                                            <DetailRow title={'Номер рейса'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_341"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Трансп. компания'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_377"].id) + ' (' + getValueProperty(detailBooking, fields["PROPERTY_379"].id) + ')'}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Тип трака'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_339"].id) !== '' && typeTrucks.typeTrucksInfo[getValueProperty(detailBooking, fields["PROPERTY_339"].id)].NAME + ' (' + getValueProperty(typeTrucks.typeTrucksInfo[getValueProperty(detailBooking, fields["PROPERTY_339"].id)], 463) + ')'}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            {getValueProperty(detailBooking, fields["PROPERTY_421"].id) == fields['PROPERTY_421'].options['417'].code && (
                                                                                <>
                                                                                    <DetailRow title={'Склад отгрузки'}
                                                                                               value={infoWarehouses[getValueProperty(detailBooking, fields["PROPERTY_291"].id)].NAME + ' (' + getValueProperty(infoWarehouses[getValueProperty(detailBooking, fields["PROPERTY_291"].id)], 335) + ')'}
                                                                                               openNotificationWithIcon={openNotificationWithIcon}/>
                                                                                    <DetailRow title={'Точка доставки'}
                                                                                               value={getValueProperty(detailBooking, fields["PROPERTY_353"].id)}
                                                                                               openNotificationWithIcon={openNotificationWithIcon}/>
                                                                                </>
                                                                            ) || (
                                                                                <>
                                                                                    <DetailRow title={'Склад отгрузки'}
                                                                                               value={getValueProperty(detailBooking, fields["PROPERTY_607"].id)}
                                                                                               openNotificationWithIcon={openNotificationWithIcon}/>
                                                                                    <DetailRow title={'Точка доставки'}
                                                                                               value={getValueProperty(detailBooking, fields["PROPERTY_609"].id)}
                                                                                               openNotificationWithIcon={openNotificationWithIcon}/>
                                                                                </>
                                                                            )}
                                                                            <DetailRow title={'Город'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_359"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Количество паллет'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_365"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Номер машины'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_403"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'ФИО водителя'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_391"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>

                                                                            {getValueProperty(detailBooking, fields["PROPERTY_421"].id) == fields['PROPERTY_421'].options['417'].code && (
                                                                                <DetailRow title={'Номер накладной'}
                                                                                           value={getValueProperty(detailBooking, fields["PROPERTY_613"].id)}
                                                                                           openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            ) || (
                                                                                <DetailRow title={'Номер накладной'}
                                                                                           value={getValueProperty(detailBooking, fields["PROPERTY_589"].id)}
                                                                                           openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            )}

                                                                            <DetailRow title={'Общий вес'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_367"].id) !== '' && parseFloat(getValueProperty(detailBooking, fields["PROPERTY_367"].id).replace(',', '.')).toFixed(2) || getValueProperty(detailBooking, fields["PROPERTY_367"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Общий объем'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_369"].id) !== '' && parseFloat(getValueProperty(detailBooking, fields["PROPERTY_369"].id).replace(',', '.')).toFixed(2) || getValueProperty(detailBooking, fields["PROPERTY_369"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Номер телефона водителя'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_395"].id) !== '' && '+7' + getValueProperty(detailBooking, fields["PROPERTY_395"].id) || getValueProperty(detailBooking, fields["PROPERTY_395"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Марка машины'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_401"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Номер прицепа'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_425"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Номер паспорта'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_393"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'ТК - экспедитор'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_411"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow
                                                                                title={'Телефон ТК - экспедитора'}
                                                                                value={getValueProperty(detailBooking, fields["PROPERTY_417"].id) !== '' && '+7' + getValueProperty(detailBooking, fields["PROPERTY_417"].id) || getValueProperty(detailBooking, fields["PROPERTY_417"].id)}
                                                                                openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Адрес ТК - экспедитора'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_413"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'ИНН ТК - экспедитора'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_415"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'ИНН водителя'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_397"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Тип владения'}
                                                                                       value={(typeSobstvennost[getValueProperty(detailBooking, fields["PROPERTY_405"].id).toLowerCase()] && typeSobstvennost[getValueProperty(detailBooking, fields["PROPERTY_405"].id).toLowerCase()] || '') + getValueProperty(detailBooking, fields["PROPERTY_405"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                            <DetailRow title={'Remark'}
                                                                                       value={getValueProperty(detailBooking, fields["PROPERTY_407"].id)}
                                                                                       openNotificationWithIcon={openNotificationWithIcon}/>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* Sidebar end **/}
                                                    {/* Main start */}
                                                    <div className="dock-booking__main">

                                                        <div style={{marginBottom: 40}}>

                                                            <div style={{marginBottom: 10}}>
                                                                <Space size={15}>
                                                                    <p><b>{warehouse.NAME}</b></p>
                                                                    <div className="main-info dock-booking__main-info"
                                                                         style={{marginTop: 0, marginBottom: 0}}>
                                                                        <div
                                                                            className="main-info__item main-info__item--green">Confirmed: {countConfirmed}</div>
                                                                        <div
                                                                            className="main-info__item main-info__item--yellow">Pending: {countPending}</div>
                                                                        <div
                                                                            className="main-info__item main-info__item--red">Infeasible: {countInfeasible}</div>
                                                                        <div
                                                                            className="main-info__item main-info__item--gray">Blocked: {countBlocked}</div>
                                                                    </div>
                                                                </Space>
                                                            </div>

                                                            {dataUser && dataUser.group == 'user_ul' && (
                                                                <>
                                                                    {selectedDays && parseInt(Object.keys(selectedDays)[0]) == parseInt(warehouseID) && (
                                                                        <Space size={8} style={{marginBottom: 8}}>
                                                                            <Button onClick={e => {
                                                                                if (stateCellMouseDown == 'blocked') {
                                                                                    dispatch(updateBlocked({
                                                                                        selectedDays: selectedDays[warehouseID],
                                                                                        dateBookings: dateBookings.format('DD.MM.YYYY'),
                                                                                        stateCellMouseDown
                                                                                    }));
                                                                                }
                                                                                if (stateCellMouseDown == 'unblocked') {
                                                                                    handleBlockSelectCells({warehouseID});
                                                                                }
                                                                            }}>{stateCellMouseDown == 'blocked' && 'Разблокировать' || stateCellMouseDown == 'unblocked' && 'Блокировать' || ''}</Button>
                                                                            <Button onClick={e => {
                                                                                setSelectedDays(null);
                                                                                setStateCellMouseDown(null);
                                                                            }}>Отменить</Button>
                                                                        </Space>
                                                                    )}
                                                                </>
                                                            )}

                                                            {/* ${dragWarehouse && dragWarehouse.ID !== warehouse.ID && 'disabled' || ''} */}

                                                            <div className={`main-table dock-booking__main-table`}>
                                                                <table
                                                                    className="main-table__table"
                                                                >
                                                                    <thead>
                                                                    <tr>
                                                                        <th>Доки</th>
                                                                        {warehouse.timeWorkList && warehouse.timeWorkList.map(timeID => {

                                                                            let time = timeInfo[timeID];

                                                                            return (
                                                                                <th>{time.NAME}</th>
                                                                            )

                                                                        })}
                                                                    </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                    {docks && docks.length > 0 && docks.map(dock => {

                                                                        let showDock = true;

                                                                        if (getValueProperty(dock, 383) == '397') {
                                                                            showDock = false;
                                                                        }

                                                                        if (showDock && dock.PROPERTY_273 && checkedDirectionDock && checkedDirectionDock.length > 0) {
                                                                            checkedDirectionDock.map(typeId => {
                                                                                if (showDock && Object.values(dock.PROPERTY_273).indexOf(typeId) < 0) {
                                                                                    showDock = false;
                                                                                }
                                                                            })
                                                                        }

                                                                        if (!showDock) {
                                                                            return;
                                                                        }

                                                                        return (
                                                                            <tr className='dock'>
                                                                                <td>
                                                                                    <div style={{marginBottom: 4}}>
                                                                                        <b className='dock__name'>{dock.NAME}</b>
                                                                                    </div>
                                                                                    {/* <div style={{ marginBottom: 4 }}>
                                                                                            <small>{dock.PROPERTY_337 && equipmentsInfo[Object.values(dock.PROPERTY_337)[0]].NAME}</small>
                                                                                        </div> */}
                                                                                    <div className="main-table__links">
                                                                                        {getValueProperty(dock, 273).indexOf('163') >= 0 &&
                                                                                            <span style={{fontSize: 16}}
                                                                                                  className="main-table__link main-table__link--down">D</span> || ''}
                                                                                        {getValueProperty(dock, 273).indexOf('165') >= 0 &&
                                                                                            <span style={{fontSize: 16}}
                                                                                                  className="main-table__link main-table__link--up">P</span> || ''}
                                                                                    </div>
                                                                                </td>
                                                                                {warehouse.timeWorkList && warehouse.timeWorkList.map((timeID, indexTimeId) => {

                                                                                    let state = '_default';

                                                                                    if (blockedCells[dock.ID] && blockedCells[dock.ID].indexOf(timeID) >= 0) {
                                                                                        state = '_blocked';
                                                                                    }

                                                                                    let booking = null;
                                                                                    let prevBooking = null;

                                                                                    if (reservedCells[dock.ID]) {

                                                                                        if (reservedCells[dock.ID].findIndex(el => el.rangeTime.indexOf(timeID) >= 0) >= 0) {

                                                                                            let findElement = reservedCells[dock.ID].filter(el => el.rangeTime.indexOf(timeID) >= 0);

                                                                                            if (warehouse.timeWorkList[indexTimeId - 1]) {
                                                                                                let prevFindElement = reservedCells[dock.ID].filter(el => el.rangeTime.indexOf(warehouse.timeWorkList[indexTimeId - 1]) >= 0);
                                                                                                prevBooking = prevFindElement[0];
                                                                                            }

                                                                                            booking = findElement[0];

                                                                                            if (findElement[0].status == fields['PROPERTY_311'].options['367'].code) { // Pending -
                                                                                                state = '_reserved';
                                                                                            } else if (findElement[0].status == fields['PROPERTY_311'].options['371'].code) { // Confirmed
                                                                                                state = '_reserved-green';
                                                                                            } else if (findElement[0].status == fields['PROPERTY_311'].options['369'].code) { // Infeasible
                                                                                                state = '_reserved-red';
                                                                                            }

                                                                                        }

                                                                                    }

                                                                                    if (selectedDays && selectedDays[warehouse.ID] && selectedDays[warehouse.ID][dock.ID] && selectedDays[warehouse.ID][dock.ID].times.indexOf(timeID) >= 0) {

                                                                                        state = state + ' selected';

                                                                                    }

                                                                                    if (searchBookingResult && searchBookingResult.result && searchBookingResult.result.length > 0 && booking && booking[fields["PROPERTY_341"].code]) {

                                                                                        if (searchBookingResult.result.findIndex(item => item[fields["PROPERTY_341"].code] && booking[fields["PROPERTY_341"].code] && Object.values(item[fields["PROPERTY_341"].code])[0] == Object.values(booking[fields["PROPERTY_341"].code])[0]) >= 0) {
                                                                                            state = state + ' _search-active';
                                                                                        }
                                                                                    }

                                                                                    let duration = booking && getValueProperty(booking, fields["PROPERTY_317"].id);

                                                                                    // if(duration == '0.125'){
                                                                                    //     duration = '0.1';
                                                                                    // }
                                                                                    if (duration && duration.indexOf('.') >= 0 && f(duration) > 2) {
                                                                                        duration = duration[0] + '.' + duration[2] + '' + duration[3];
                                                                                    }

                                                                                    if (booking && getValueProperty(booking, fields["PROPERTY_419"].id) != '') {
                                                                                        duration = getValueProperty(booking, fields["PROPERTY_419"].id);
                                                                                    }

                                                                                    let durationFloat = duration && duration.indexOf('.') >= 0 && duration.split('.').length > 1 && duration.split('.')[1] || false;

                                                                                    duration = Math.ceil(duration);

                                                                                    if (warehouse && warehouse.PROPERTY_315 && Object.values(warehouse.PROPERTY_315)[0] == 375) {

                                                                                        if (durationFloat && durationFloat / 100 <= 0.5) {
                                                                                            duration = duration - 0.5;
                                                                                        }

                                                                                        duration = duration * 2;
                                                                                    }

                                                                                    if (dataUser && dataUser.group && dataUser.group !== 'user_view' && dataUser.group !== 'user_ul' && booking && getValueProperty(booking, fields["PROPERTY_289"].id) !== dataUser.group) {
                                                                                        state = '_blocked';
                                                                                        duration = 1;
                                                                                    } else {
                                                                                        if (duration && duration > 1 && prevBooking && prevBooking.ID == booking.ID) {
                                                                                            return;
                                                                                        }
                                                                                    }

                                                                                    return (
                                                                                        <td
                                                                                            data-time-id={timeID}
                                                                                            data-dock-id={dock.ID}
                                                                                            data-warehouse-id={warehouse.ID}
                                                                                            data-booking-id={booking && booking.ID || ''}

                                                                                            className={state}

                                                                                            colSpan={duration}

                                                                                            onMouseDown={e => {

                                                                                                // if(e.target.classList.contains('selected')){

                                                                                                //     let modifiedSelectedDays = selectedDays;

                                                                                                //     delete modifiedSelectedDays[warehouseID][dock.ID];

                                                                                                //     console.log(modifiedSelectedDays);

                                                                                                //     e.target.classList.remove('selected');

                                                                                                //     setSelectedDays(modifiedSelectedDays);

                                                                                                // }

                                                                                                if (dataUser && dataUser.group == 'user_ul') {
                                                                                                    cellMouseDown(e, timeID, dock.ID, warehouseID)
                                                                                                }
                                                                                            }}

                                                                                            onMouseOver={e => {
                                                                                                if (dataUser && dataUser.group == 'user_ul') {
                                                                                                    cellMouseOver(e, timeID, dock.ID, warehouseID)
                                                                                                }
                                                                                            }}

                                                                                            onDrop={e => {
                                                                                                e.preventDefault();

                                                                                                document.querySelectorAll('._drag-cell').forEach(item => item.classList.remove('_drag-cell'));

                                                                                                if (dragStateCell == 'error') {
                                                                                                    openNotificationWithIcon('error', 'Ячейка заблокирована');
                                                                                                } else {

                                                                                                    if (statusCheckedBlockedCells == 'IDLE') {

                                                                                                        dispatch(checkedBlockedCells({
                                                                                                            dateBookings: dateBookings.format('DD.MM.YYYY'),
                                                                                                            dockId: dock.ID,
                                                                                                            timeList: dragTimes,
                                                                                                            timeListAll: timeList,
                                                                                                            dragBooking: dragBooking,
                                                                                                            bookingID: dragBooking.ID
                                                                                                        }));

                                                                                                    }

                                                                                                }

                                                                                                setDragStateCell('');
                                                                                            }}
                                                                                            onDragOver={e => {
                                                                                                e.preventDefault();

                                                                                                let stateDragCell = '';
                                                                                                if (dragWarehouse.ID !== warehouse.ID) {
                                                                                                    stateDragCell = 'error';
                                                                                                }
                                                                                                if (dragWarehouse.ID == warehouse.ID && dragBooking) {
                                                                                                    if (dragBooking[fields["PROPERTY_317"].code] || dragBooking[fields["PROPERTY_419"].code]) {

                                                                                                        let duration = getValueProperty(dragBooking, fields["PROPERTY_317"].id);

                                                                                                        if (duration && duration.indexOf('.') >= 0 && f(duration) > 2) {
                                                                                                            duration = duration[0] + '.' + duration[2] + '' + duration[3];
                                                                                                        }

                                                                                                        if (getValueProperty(dragBooking, fields["PROPERTY_419"].id) != '') {
                                                                                                            duration = getValueProperty(dragBooking, fields["PROPERTY_419"].id);
                                                                                                        }

                                                                                                        let durationFloat = duration.indexOf('.') >= 0 && duration.split('.').length > 1 && duration.split('.')[1] || false;

                                                                                                        // if(durationFloat && durationFloat.length > 2){
                                                                                                        //     duration = '0.1';
                                                                                                        // }

                                                                                                        duration = Math.ceil(duration);

                                                                                                        if (warehouse && warehouse.PROPERTY_315 && Object.values(warehouse.PROPERTY_315)[0] == 375) {

                                                                                                            if (durationFloat && durationFloat / 100 <= 0.5) {
                                                                                                                duration = duration - 0.5;
                                                                                                            }

                                                                                                            duration = duration * 2;
                                                                                                        }

                                                                                                        document.querySelectorAll('._drag-cell').forEach(item => item.classList.remove('_drag-cell'));

                                                                                                        let timeList = [timeID];

                                                                                                        if (dragBooking && dragBooking.ID !== e.target.getAttribute('data-booking-id')) {
                                                                                                            e.target.classList.add('_drag-cell');
                                                                                                        } else if (dragBooking && dragBooking.ID == e.target.getAttribute('data-booking-id')) {
                                                                                                            e.target.classList.add('_drag-cell');
                                                                                                        }

                                                                                                        if (state == '_blocked' ||
                                                                                                            state == '_reserved' ||
                                                                                                            state == '_reserved-red' ||
                                                                                                            state == '_reserved-green'
                                                                                                        ) {
                                                                                                            stateDragCell = 'error';
                                                                                                        }

                                                                                                        if (duration > 1) {

                                                                                                            let nextElement = e.target;

                                                                                                            for (let index = 1; index < duration; index++) {


                                                                                                                // nextElement = 123;

                                                                                                                if (nextElement.nextElementSibling) {

                                                                                                                    nextElement = nextElement.nextElementSibling;

                                                                                                                    if (dragBooking && dragBooking.ID !== nextElement.getAttribute('data-booking-id')) {
                                                                                                                        nextElement.classList.add('_drag-cell');
                                                                                                                    } else if (dragBooking && dragBooking.ID == nextElement.getAttribute('data-booking-id')) {
                                                                                                                        nextElement.classList.add('_drag-cell');
                                                                                                                    }

                                                                                                                    if (nextElement.classList.contains('_blocked') ||
                                                                                                                        nextElement.classList.contains('_reserved') ||
                                                                                                                        nextElement.classList.contains('_reserved-red') ||
                                                                                                                        nextElement.classList.contains('_reserved-green') ||
                                                                                                                        !nextElement
                                                                                                                    ) {
                                                                                                                        stateDragCell = 'error';
                                                                                                                    }
                                                                                                                } else {
                                                                                                                    stateDragCell = 'error';
                                                                                                                }

                                                                                                            }

                                                                                                            let findIndexTime = Object.values(timeInfo).findIndex(item => item.ID == timeID);

                                                                                                            if (getValueProperty(warehouse, 315) == '373') {
                                                                                                                duration = duration * 2 - 1;
                                                                                                            }

                                                                                                            for (let index = 1; index < duration; index++) {

                                                                                                                timeList = [
                                                                                                                    ...timeList,
                                                                                                                    Object.keys(timeInfo)[findIndexTime + index]
                                                                                                                ]

                                                                                                            }
                                                                                                        }

                                                                                                        setDragDock(dock);
                                                                                                        setDragTimes(timeList);

                                                                                                    }
                                                                                                }

                                                                                                setDragStateCell(stateDragCell);

                                                                                            }}
                                                                                        >
                                                                                            {booking && state !== '_blocked' && (
                                                                                                <div
                                                                                                    className='booking'>
                                                                                                    <div
                                                                                                        style={{marginBottom: 4}}>
                                                                                                        <div>
                                                                                                            <Button
                                                                                                                title={booking.NAME}
                                                                                                                style={{
                                                                                                                    padding: 0,
                                                                                                                    height: 'auto',
                                                                                                                    lineHeight: 1
                                                                                                                }}
                                                                                                                type={'link'}
                                                                                                                onClick={e => {
                                                                                                                    setDetailBooking(booking);
                                                                                                                    setShowDetailBooking(true);
                                                                                                                    setShowDetailWarehouse(warehouseID)
                                                                                                                }}
                                                                                                            >
                                                                                                                <span
                                                                                                                    style={{maxWidth: '100%'}}
                                                                                                                    className='ellipsis booking__name'>{booking.NAME}</span>
                                                                                                            </Button>
                                                                                                        </div>
                                                                                                        <div style={{
                                                                                                            display: 'flex',
                                                                                                            alignItems: 'center',
                                                                                                            justifyContent: 'space-between',
                                                                                                            gap: 4
                                                                                                        }}>
                                                                                                            {booking[fields["PROPERTY_377"].code] && dataUser && dataUser.group == 'user_ul' && (
                                                                                                                <span
                                                                                                                    className='filters-cards__item-data'>
                                                                                                                        {listProvider && listProvider.listProviderInfo[getValueProperty(booking, fields["PROPERTY_377"].id)] && Object.values(listProvider.listProviderInfo[getValueProperty(booking, fields["PROPERTY_377"].id)].PROPERTY_459)[0] || ''}
                                                                                                                    </span>
                                                                                                            )}
                                                                                                            {booking[fields["PROPERTY_385"].code] && (
                                                                                                                <span
                                                                                                                    className='filters-cards__item-data'>
                                                                                                                        {Object.values(booking[fields["PROPERTY_385"].code]) && Object.values(booking[fields["PROPERTY_385"].code]).length > 1 && Object.values(booking[fields["PROPERTY_385"].code]).map(item => {
                                                                                                                            return item == fields['PROPERTY_385'].options['399'].id && 'D' || 'P'
                                                                                                                        }).join(' ') || Object.values(booking[fields["PROPERTY_385"].code])[0] == fields['PROPERTY_385'].options['399'].id && 'Drop' || 'Pickup'}
                                                                                                                    </span>
                                                                                                            )}
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    {typeUser && typeUser != 'isView' && (

                                                                                                        <Dropdown.Button
                                                                                                            trigger={['click']}
                                                                                                            size={'small'}
                                                                                                            dropdownRender={() => {
                                                                                                                return (
                                                                                                                    <div
                                                                                                                        style={contentStyle}>
                                                                                                                        <Space.Compact
                                                                                                                            direction="vertical">
                                                                                                                            {(typeUser == 'isUniliver' || typeUser == 'isCarrier') && getValueProperty(booking, fields["PROPERTY_311"].id) !== '' && (
                                                                                                                                <Button
                                                                                                                                    style={{textAlign: 'left'}}
                                                                                                                                    onClick={e => {

                                                                                                                                        let dataForm = {};

                                                                                                                                        if (booking) {
                                                                                                                                            Object.keys(booking).map(key => {
                                                                                                                                                if (booking[key] && Object.values(booking[key])) {
                                                                                                                                                    dataForm = {
                                                                                                                                                        ...dataForm,
                                                                                                                                                        [key]: Object.values(booking[key])[0]
                                                                                                                                                    }
                                                                                                                                                } else {
                                                                                                                                                    dataForm = {
                                                                                                                                                        ...dataForm,
                                                                                                                                                        [key]: booking[key]
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        }

                                                                                                                                        formAuto.setFieldsValue(dataForm);
                                                                                                                                        setIsModalAutoOpen(true);
                                                                                                                                        setDefaultBookingInfo(booking);

                                                                                                                                    }}
                                                                                                                                    icon={
                                                                                                                                        <CarOutlined/>}>Информация
                                                                                                                                    по
                                                                                                                                    а/м
                                                                                                                                    и
                                                                                                                                    водителю</Button>
                                                                                                                            )}
                                                                                                                            {(
                                                                                                                                typeUser == 'isUniliver' ||
                                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) == '' ||
                                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) !== '' && getValueProperty(booking, fields["PROPERTY_311"].id) !== fields['PROPERTY_311'].options['371'].code
                                                                                                                            ) && (
                                                                                                                                <Button
                                                                                                                                    style={{textAlign: 'left'}}
                                                                                                                                    onClick={e => {

                                                                                                                                        let duration = getValueProperty(booking, fields["PROPERTY_317"].id);

                                                                                                                                        if (duration && duration.indexOf('.') >= 0 && f(duration) > 2) {
                                                                                                                                            duration = duration[0] + '.' + duration[2] + '' + duration[3];
                                                                                                                                        }

                                                                                                                                        if (booking && getValueProperty(booking, fields["PROPERTY_419"].id) != '') {
                                                                                                                                            duration = getValueProperty(booking, fields["PROPERTY_419"].id);
                                                                                                                                        }

                                                                                                                                        let durationFloat = duration.indexOf('.') >= 0 && duration.split('.').length > 1 && duration.split('.')[1] || false;

                                                                                                                                        duration = Math.ceil(duration);

                                                                                                                                        if (getValueProperty(warehouse, 315) == '375') {

                                                                                                                                            if (durationFloat && durationFloat / 100 <= 0.5) {
                                                                                                                                                duration = duration - 0.5;
                                                                                                                                            }

                                                                                                                                            duration = duration * 2;
                                                                                                                                        }

                                                                                                                                        setShowSelectDock({
                                                                                                                                            warehouse,
                                                                                                                                            dateBookings: dateBookingsSelect.format('DD.MM.YYYY'),
                                                                                                                                            duration: duration,
                                                                                                                                            booking,
                                                                                                                                            method: 'update'
                                                                                                                                        })
                                                                                                                                        setIsModalTimesOpen(true);
                                                                                                                                    }}
                                                                                                                                    icon={
                                                                                                                                        <CalendarOutlined/>}>Изменить
                                                                                                                                    дату/время</Button>
                                                                                                                            )}
                                                                                                                            {(
                                                                                                                                typeUser == 'isUniliver' ||
                                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) !== '' && getValueProperty(booking, fields["PROPERTY_311"].id) !== fields['PROPERTY_311'].options['371'].code
                                                                                                                            ) && (
                                                                                                                                <Button
                                                                                                                                    style={{textAlign: 'left'}}
                                                                                                                                    onClick={e => {

                                                                                                                                        if (updateBookingStatus == 'IDLE') {
                                                                                                                                            dispatch(updateBooking({
                                                                                                                                                'ELEMENT_ID': booking.ID,
                                                                                                                                                'FIELDS': {
                                                                                                                                                    ...booking,
                                                                                                                                                    [fields["PROPERTY_285"].code]: null,
                                                                                                                                                    [fields["PROPERTY_323"].code]: null,
                                                                                                                                                    [fields["PROPERTY_325"].code]: null,
                                                                                                                                                    [fields["PROPERTY_311"].code]: null,
                                                                                                                                                    [fields["PROPERTY_621"].code]: getValueProperty(booking, fields["PROPERTY_439"].id),
                                                                                                                                                }
                                                                                                                                            }));
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    icon={
                                                                                                                                        <BackwardOutlined/>}>Перенести
                                                                                                                                    в
                                                                                                                                    нераспределенные</Button>
                                                                                                                            )}
                                                                                                                            {(
                                                                                                                                typeUser == 'isUniliver' &&
                                                                                                                                getValueProperty(booking, fields["PROPERTY_311"].id) !== '' &&
                                                                                                                                getValueProperty(booking, fields["PROPERTY_311"].id) !== fields['PROPERTY_311'].options['371'].code
                                                                                                                            ) && (
                                                                                                                                <Button
                                                                                                                                    style={{textAlign: 'left'}}
                                                                                                                                    onClick={e => {
                                                                                                                                        if (updateBookingStatus == 'IDLE') {
                                                                                                                                            dispatch(updateBooking({
                                                                                                                                                'ELEMENT_ID': booking.ID,
                                                                                                                                                'FIELDS': {
                                                                                                                                                    ...booking,
                                                                                                                                                    [fields["PROPERTY_311"].code]: fields['PROPERTY_311'].options['371'].id
                                                                                                                                                }
                                                                                                                                            }));
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    icon={
                                                                                                                                        <CheckOutlined/>}>Подтвердить
                                                                                                                                    рейс</Button>
                                                                                                                            )}
                                                                                                                            {typeUser == 'isUniliver' && (
                                                                                                                                <Popconfirm
                                                                                                                                    placement="top"
                                                                                                                                    title={'Кол-во слотов'}
                                                                                                                                    description={
                                                                                                                                        <Input
                                                                                                                                            value={customDuration}
                                                                                                                                            onChange={e => {
                                                                                                                                                setCustomDuration(e.target.value);
                                                                                                                                            }}/>}
                                                                                                                                    onConfirm={e => {
                                                                                                                                        if (updateBookingStatus == 'IDLE') {

                                                                                                                                            let customDurationApp = customDuration;

                                                                                                                                            if (getValueProperty(warehouse, 315) == '375') {
                                                                                                                                                customDurationApp = customDurationApp / 2;
                                                                                                                                            }

                                                                                                                                            dispatch(updateBooking({
                                                                                                                                                'ELEMENT_ID': booking.ID,
                                                                                                                                                'FIELDS': {
                                                                                                                                                    ...booking,
                                                                                                                                                    [fields["PROPERTY_419"].code]: customDurationApp,
                                                                                                                                                    [fields["PROPERTY_285"].code]: null,
                                                                                                                                                    [fields["PROPERTY_311"].code]: null,
                                                                                                                                                    [fields["PROPERTY_323"].code]: null,
                                                                                                                                                    [fields["PROPERTY_325"].code]: null,
                                                                                                                                                }
                                                                                                                                            }));

                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    okText="Да"
                                                                                                                                    cancelText="Нет"
                                                                                                                                >
                                                                                                                                    <Button
                                                                                                                                        style={{textAlign: 'left'}}
                                                                                                                                        icon={
                                                                                                                                            <ClockCircleOutlined/>}>Изменить
                                                                                                                                        кол-во
                                                                                                                                        слотов</Button>
                                                                                                                                </Popconfirm>
                                                                                                                            )}
                                                                                                                            {typeUser == 'isUniliver' && (
                                                                                                                                <Popconfirm
                                                                                                                                    placement="top"
                                                                                                                                    title={'Вы хотите удалить рейс?'}
                                                                                                                                    onConfirm={e => {
                                                                                                                                        if (deleteBookingStatus == 'IDLE') {
                                                                                                                                            dispatch(deleteBooking({
                                                                                                                                                'ELEMENT_ID': booking.ID,
                                                                                                                                                'CODE': booking.CODE,
                                                                                                                                                'FIELDS': booking
                                                                                                                                            }));
                                                                                                                                        }
                                                                                                                                    }}
                                                                                                                                    okText="Да"
                                                                                                                                    cancelText="Нет"
                                                                                                                                >
                                                                                                                                    <Button
                                                                                                                                        style={{textAlign: 'left'}}
                                                                                                                                        icon={
                                                                                                                                            <DeleteOutlined/>}
                                                                                                                                    >Удалить
                                                                                                                                        рейс</Button>
                                                                                                                                </Popconfirm>
                                                                                                                            )}
                                                                                                                            {typeUser == 'isUniliver' && getValueProperty(booking, fields["PROPERTY_421"].id) == fields['PROPERTY_421'].options['417'].code && (
                                                                                                                                <Button
                                                                                                                                    style={{textAlign: 'left'}}
                                                                                                                                    onClick={e => {

                                                                                                                                        let dataForm = {};

                                                                                                                                        if (booking) {
                                                                                                                                            Object.keys(booking).map(key => {
                                                                                                                                                if (booking[key] && Object.values(booking[key])) {
                                                                                                                                                    dataForm = {
                                                                                                                                                        ...dataForm,
                                                                                                                                                        [key]: key == 'NAME' && booking[key] || Object.values(booking[key])[0]
                                                                                                                                                    }
                                                                                                                                                } else {
                                                                                                                                                    dataForm = {
                                                                                                                                                        ...dataForm,
                                                                                                                                                        [key]: booking[key]
                                                                                                                                                    }
                                                                                                                                                }
                                                                                                                                            })
                                                                                                                                        }

                                                                                                                                        formApp.setFieldsValue(dataForm);
                                                                                                                                        setIsModalEditAppOpen(true);
                                                                                                                                        setDefaultBookingInfo(booking);

                                                                                                                                    }}
                                                                                                                                    icon={
                                                                                                                                        <EditOutlined/>}>Отредактировать
                                                                                                                                    рейс</Button>
                                                                                                                            )}
                                                                                                                        </Space.Compact>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }}
                                                                                                        >
                                                                                                            {(
                                                                                                                typeUser == 'isUniliver' ||
                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) == '' ||
                                                                                                                typeUser == 'isCarrier' && getValueProperty(booking, fields["PROPERTY_311"].id) !== '' && getValueProperty(booking, fields["PROPERTY_311"].id) !== fields['PROPERTY_311'].options['371'].code
                                                                                                            ) && (
                                                                                                                <DragOutlined
                                                                                                                    draggable={booking && (state.indexOf('_reserved') >= 0 || state.indexOf('_reserved-red') >= 0 || state.indexOf('_reserved-green') >= 0) && true || false}
                                                                                                                    onDragStart={e => {

                                                                                                                        if (booking && (state.indexOf('_reserved') >= 0 || state.indexOf('_reserved-red') >= 0 || state.indexOf('_reserved-green') >= 0)) {
                                                                                                                            setDragBooking(booking);
                                                                                                                            setDragWarehouse(warehouse);
                                                                                                                        }

                                                                                                                    }}
                                                                                                                    onDragEnd={e => {
                                                                                                                        if (booking && (state.indexOf('_reserved') >= 0 || state.indexOf('_reserved-red') >= 0 || state.indexOf('_reserved-green') >= 0)) {
                                                                                                                            document.querySelectorAll('._drag-cell').forEach(item => item.classList.remove('_drag-cell'));
                                                                                                                        }
                                                                                                                    }}
                                                                                                                />
                                                                                                            )}
                                                                                                        </Dropdown.Button>

                                                                                                    )}

                                                                                                </div>
                                                                                            )}
                                                                                        </td>
                                                                                    )

                                                                                })}
                                                                            </tr>
                                                                        )
                                                                    })}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    {/* Main end */}
                                                </div>
                                            )
                                        })}
                                    </>
                                ) || isSelectWarehouse && (
                                    <Alert message="Рейсов с текущими фильтрами не обнаружено" type="info"/>
                                ) || (
                                    <Alert message="Выберите склад" type="info"/>
                                )}
                            </>
                        )}

                    </div>
                </div>
            </main>
            <Modal
                title="New Appointment"
                open={isModalAppointmentOpen}
                onOk={e => {
                    setIsModalAppointmentOpen(false);
                }}
                onCancel={e => {
                    setIsModalAppointmentOpen(false);
                }}
                footer={null}
                width={'100%'}
                style={{top: 20, maxWidth: '1000px'}}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    form={form}
                    style={{
                        maxWidth: '100%',
                    }}
                    initialValues={{
                        [fields["PROPERTY_621"].code]: dateBookings && dateBookings || dayjs(),
                        [fields["PROPERTY_385"].code]: fields['PROPERTY_385'].options['401'].code,
                        [fields["PROPERTY_339"].code]: '371',
                        [fields["PROPERTY_379"].code]: '50438571'
                    }}
                    onFinish={e => {
                        // console.log(e);
                        if (!e.errorFields) {
                            if (addBookingStatus == 'IDLE') {

                                let customDuration = e[fields["PROPERTY_317"].code];

                                if (infoWarehouses[e[fields["PROPERTY_291"].code]] && infoWarehouses[e[fields["PROPERTY_291"].code]].PROPERTY_315 && Object.values(infoWarehouses[e[fields["PROPERTY_291"].code]].PROPERTY_315)[0] == '375') {
                                    customDuration = e[fields["PROPERTY_317"].code] / 2;
                                }

                                setDateBookingsSelect(form.getFieldValue(fields["PROPERTY_621"].code));

                                dispatch(addBooking({
                                    'ELEMENT_CODE': infoWarehouses[e[fields["PROPERTY_291"].code]].NAME + '_' + e[fields["PROPERTY_341"].code],
                                    'FIELDS': {
                                        ...e,
                                        NAME: infoWarehouses[e[fields["PROPERTY_291"].code]].NAME + '_' + e[fields["PROPERTY_341"].code],
                                        [fields["PROPERTY_589"].code]: e[fields["PROPERTY_613"].code],
                                        [fields["PROPERTY_421"].code]: fields['PROPERTY_421'].options['417'].id,
                                        [fields["PROPERTY_387"].code]: fields['PROPERTY_387'].options['405'].id,
                                        [fields["PROPERTY_439"].code]: form.getFieldValue(fields["PROPERTY_621"].code),
                                        [fields["PROPERTY_317"].code]: customDuration,
                                        [fields["PROPERTY_289"].code]: form.getFieldValue(fields["PROPERTY_377"].code)
                                    }
                                }));
                            }
                        }
                    }}
                    onFinishFailed={e => {
                        // console.log(e);
                    }}
                    autoComplete="off"
                >
                    <Row gutter={24}>
                        <Form.Item
                            name="NAME"
                            hidden={true}
                        >
                            <Input/>
                        </Form.Item>
                        <Col span={8}>
                            <Form.Item
                                label="Shipment ID"
                                name={fields["PROPERTY_341"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста введите shipment ID',
                                    },
                                ]}
                            >
                                <Input
                                    onChange={e => {
                                        clearTimeout(setTimeoutShipment);
                                        setTimeoutShipment = setTimeout(function () {

                                            form.setFieldValue('NAME', e.target.value);
                                            form.setFieldValue(fields["PROPERTY_341"].code, e.target.value);
                                            form.validateFields([fields["PROPERTY_341"].code]);

                                            if (getBookingShipmentStatus == 'IDLE') {
                                                dispatch(getBookingShipment({
                                                    shipment: e.target.value
                                                }));
                                            }
                                        }, 1000)

                                    }}
                                />
                                {getBookingShipmentStatus == 'LOADING' && <Spin/>}
                                {getBookingShipmentResult && getBookingShipmentResult.result && getBookingShipmentResult.result.length > 0 && form.getFieldValue(fields["PROPERTY_341"].code) != '' && (
                                    <Alert style={{marginTop: 4}} message="Такой shipment ID существует" type="error"/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Номера накладных"
                                name={fields["PROPERTY_613"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста заполните поле',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={fields["PROPERTY_289"].code}
                                hidden={true}
                            />
                            <Form.Item
                                label="Перевозчик"
                                name={fields["PROPERTY_377"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста выберите перевозчика',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    value={valueSearchUsersProvider}
                                    placeholder="Введите код/название"
                                    onSearch={(newValue) => {

                                        if (newValue) {

                                            if (timeoutSearchUsersProvider) {
                                                clearTimeout(timeoutSearchUsersProvider);
                                                timeoutSearchUsersProvider = null;
                                            }

                                            timeoutSearchUsersProvider = setTimeout(function () {

                                                if (newValue.length > 3) {

                                                    if (searchUsersProviderStatus == 'IDLE') {

                                                        dispatch(searchUsersProvider({code: newValue}));

                                                    }

                                                } else {
                                                    setDataSearchUsersProvider([]);
                                                }

                                            }, 300);

                                        } else {
                                            setDataSearchUsersProvider([]);
                                        }
                                    }}
                                    onChange={e => {

                                        // console.log(e, form.getFieldValue(fields["PROPERTY_377"].code));

                                        setValueSearchUsersProvider(e);

                                        form.setFieldValue(fields["PROPERTY_379"].code, searchUsersProviderResult.info[e].NAME);
                                        form.setFieldValue(fields["PROPERTY_289"].code, form.getFieldValue(fields["PROPERTY_377"].code));

                                    }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    notFoundContent={searchUsersProviderStatus != 'IDLE' ? <Spin size="small"/> : null}
                                    options={(dataSearchUsersProvider || []).map((d) => {
                                        let labelOption = d.label;

                                        if (searchUsersProviderResult.info[d.value] && searchUsersProviderResult.info[d.value].PROPERTY_457) {
                                            labelOption = labelOption + ' (' + Object.values(searchUsersProviderResult.info[d.value].PROPERTY_457)[0] + ')'
                                        }
                                        return {
                                            value: d.value,
                                            label: labelOption,
                                        }
                                    })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name={fields["PROPERTY_379"].code}
                        hidden={true}
                    >
                        <Input/>
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Вес общий"
                                name={fields["PROPERTY_367"].code}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Объем общий"
                                name={fields["PROPERTY_369"].code}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Количество паллет"
                                name={fields["PROPERTY_365"].code}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Номер а/м"
                                name={fields["PROPERTY_403"].code}
                                rules={[
                                    {
                                        type: 'string',
                                        max: 12,
                                        message: 'Максимальное кол-во символов 12'
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^[A-ZА-Я0-9]+$/i
                                        ),
                                        message: "Большие буквы, пробелы не допускаются"
                                    }
                                ]}
                                tooltip={{
                                    title: 'Большие буквы, пробелы не допускаются, не больше 12 символов',
                                    icon: <InfoCircleOutlined/>
                                }}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="ФИО водителя"
                                name={fields["PROPERTY_391"].code}
                                rules={[
                                    {
                                        type: 'string',
                                        max: 35,
                                        message: 'Максимальное кол-во символов 35'
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^[а-яА-Я\s]+$/i
                                        ),
                                        message: "Только на рус.яз. Не должно быть специальных знаков (запятая, слэш и т.п.)"
                                    }
                                ]}
                                tooltip={{
                                    title: 'Только на рус.яз. ФИО может быть заполнено в любом формате, начиная с Фамилии. Не должно быть специальных знаков (запятая, слэш и т.п.). Не больше 35 букв',
                                    icon: <InfoCircleOutlined/>
                                }}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Точка доставки"
                            >
                                <Select
                                    showSearch
                                    value={valueSearchTypeLocation}
                                    placeholder="Введите код"
                                    onSearch={(newValue) => {

                                        if (newValue) {

                                            if (timeoutSearchTypeLocation) {
                                                clearTimeout(timeoutSearchTypeLocation);
                                                timeoutSearchTypeLocation = null;
                                            }

                                            timeoutSearchTypeLocation = setTimeout(function () {

                                                if (newValue.length > 3) {

                                                    if (searchTypeLocationStatus == 'IDLE') {

                                                        dispatch(searchTypeLocation({code: newValue}));

                                                    }

                                                } else {
                                                    setDataSearchTypeLocation([]);
                                                }

                                            }, 300);

                                        } else {
                                            setDataSearchTypeLocation([]);
                                        }
                                    }}
                                    onChange={e => {

                                        // console.log(e);

                                        setValueSearchTypeLocation(e);

                                        form.setFieldValue(fields["PROPERTY_353"].code, e);

                                        let currentShip = dataSearchTypeLocation.filter(item => item.value == e);

                                        form.setFieldValue(fields["PROPERTY_355"].code, currentShip[0].label);
                                    }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    notFoundContent={searchTypeLocationStatus != 'IDLE' ? <Spin size="small"/> : null}
                                    options={(dataSearchTypeLocation || []).map((d) => ({
                                        value: d.value,
                                        label: d.label + '(' + d.value + ')',
                                    }))}
                                    // filterOption={(input, option) =>
                                    //     (option?.label ?? '').toLowerCase().includes(input.toLowerCase()) || (option?.value ?? '').toLowerCase().includes(input.toLowerCase())
                                    // }
                                    // options={listLocations.selectOptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name={fields["PROPERTY_353"].code}
                                label="Код точки доставки"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={fields["PROPERTY_355"].code}
                                label="Наименование точки доставки"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={fields["PROPERTY_407"].code}
                                label="Комментарии"
                            >
                                <TextArea rows={4}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Дата"
                                name={fields["PROPERTY_621"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста введите дату',
                                    },
                                ]}
                            >
                                <DatePicker
                                    locale={locale}
                                    size="large"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Склад погрузки"
                                name={fields["PROPERTY_291"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста выберите склад',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Выберите склад"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={activeWarehousesOptions}
                                />
                                {/* {infoWarehouses && Object.keys(infoWarehouses).map(idWarehouse => {
                                        let warehouse = infoWarehouses[idWarehouse];
                                        return (
                                            <Option value={idWarehouse}>{warehouse.NAME}</Option>
                                        )
                                    })}
                                </Select> */}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Тип а/м"
                                name={fields["PROPERTY_339"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста выберите тип а/м',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Введите название"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={typeTrucks.selectOptions}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Погрузка/выгрузка:"
                                name={fields["PROPERTY_385"].code}
                            >
                                <Select
                                    placeholder="Выберите вариант"
                                    options={[
                                        {value: fields['PROPERTY_385'].options['399'].code, label: 'Drop (Выгрузка)'},
                                        {value: fields['PROPERTY_385'].options['401'].code, label: 'Pickup (Погрузка)'}
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Количество слотов"
                                name={fields["PROPERTY_317"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Введите значение',
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}></Col>
                    </Row>
                    <Form.Item>
                        <Space size={8}>
                            <Button type="primary" htmlType="submit">
                                Создать
                            </Button>
                            <Button onClick={e => {
                                setIsModalAppointmentOpen(false);
                            }}>
                                Закрыть
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>

            </Modal>
            <Modal
                title="Редактирование ручного рейса"
                open={isModalEditAppOpen}
                onOk={e => {
                    setIsModalEditAppOpen(false);
                }}
                onCancel={e => {
                    setIsModalEditAppOpen(false);
                }}
                width={'100%'}
                style={{top: 20, maxWidth: '1000px'}}
                footer={null}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    form={formApp}
                    style={{
                        maxWidth: '100%',
                    }}
                    onFinish={e => {
                        if (!e.errorFields) {
                            if (updateBookingStatus == 'IDLE') {

                                // console.log(e, listUsersProdider);

                                let provider = '';

                                if (listUsersProdider && listUsersProdider.selectOptions && listUsersProdider.selectOptions.length > 0) {
                                    provider = listUsersProdider.selectOptions.filter(item => item.value == e[fields["PROPERTY_377"].code]);
                                    provider = provider[0] && provider[0].label;
                                }

                                if (e[fields["PROPERTY_379"].code]) {
                                    provider = e[fields["PROPERTY_379"].code];
                                }

                                dispatch(updateBooking({
                                    'ELEMENT_ID': defaultBookingInfo.ID,
                                    'FIELDS': {
                                        ...defaultBookingInfo,
                                        ...e,
                                        [fields["PROPERTY_311"].code]: null,
                                        [fields["PROPERTY_285"].code]: null,
                                        [fields["PROPERTY_323"].code]: null,
                                        [fields["PROPERTY_325"].code]: null,
                                        [fields["PROPERTY_379"].code]: provider
                                    }
                                }));
                            }
                        }
                    }}
                    onFinishFailed={e => {

                    }}
                    autoComplete="off"
                >
                    <Form.Item
                        name="NAME"
                        hidden={true}
                    >
                        <Input/>
                    </Form.Item>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Shipment ID"
                                name={fields["PROPERTY_341"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста введите shipment ID',
                                    },
                                ]}
                            >
                                <Input
                                    defaultValue={formApp.getFieldValue(fields["PROPERTY_341"].code)}
                                    onChange={e => {

                                        clearTimeout(setTimeoutShipment);
                                        setTimeoutShipment = setTimeout(function () {

                                            formApp.setFieldValue('NAME', e.target.value);
                                            formApp.setFieldValue(fields["PROPERTY_341"].code, e.target.value);
                                            formApp.validateFields([fields["PROPERTY_341"].code]);

                                            if (getBookingShipmentStatus == 'IDLE') {
                                                dispatch(getBookingShipment({
                                                    shipment: e.target.value
                                                }));
                                            }
                                        }, 1000)

                                    }}
                                />
                                {getBookingShipmentStatus == 'LOADING' && <Spin/>}
                                {getBookingShipmentResult && getBookingShipmentResult.result && getBookingShipmentResult.result.length > 0 && formApp.getFieldValue(fields["PROPERTY_341"].code) != '' && (
                                    <Alert style={{marginTop: 4}} message="Такой shipment ID существует" type="error"/>
                                )}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Номера накладных"
                                name={fields["PROPERTY_613"].code}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Перевозчик"
                                name={fields["PROPERTY_377"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста выберите перевозчика',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    value={valueSearchUsersProvider}
                                    placeholder="Введите код/название"
                                    onSearch={(newValue) => {

                                        if (newValue) {

                                            if (timeoutSearchUsersProvider) {
                                                clearTimeout(timeoutSearchUsersProvider);
                                                timeoutSearchUsersProvider = null;
                                            }

                                            timeoutSearchUsersProvider = setTimeout(function () {

                                                if (newValue.length > 3) {

                                                    if (searchUsersProviderStatus == 'IDLE') {

                                                        dispatch(searchUsersProvider({code: newValue}));

                                                    }

                                                } else {
                                                    setDataSearchUsersProvider([]);
                                                }

                                            }, 300);

                                        } else {
                                            setDataSearchUsersProvider([]);
                                        }
                                    }}
                                    onChange={e => {

                                        // console.log(e);

                                        setValueSearchUsersProvider(e);

                                        if (searchUsersProviderResult && searchUsersProviderResult.info && searchUsersProviderResult.info[e]) {
                                            // console.log(searchUsersProviderResult.info[e].NAME);

                                            formApp.setFieldValue(fields["PROPERTY_379"].code, searchUsersProviderResult.info[e].NAME);
                                        }

                                    }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    notFoundContent={searchUsersProviderStatus != 'IDLE' ? <Spin size="small"/> : null}
                                    options={(dataSearchUsersProvider || []).map((d) => {

                                        // console.log(d);
                                        let labelOption = d.label;

                                        if (searchUsersProviderResult.info[d.value] && searchUsersProviderResult.info[d.value].PROPERTY_457) {
                                            labelOption = labelOption + ' (' + Object.values(searchUsersProviderResult.info[d.value].PROPERTY_457)[0] + ')'
                                        }
                                        return {
                                            value: d.value,
                                            label: labelOption,
                                        }
                                    })}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item
                        name={fields["PROPERTY_379"].code}
                        hidden={true}
                    />
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Вес накладной"
                                name={fields["PROPERTY_367"].code}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Обьем накладной"
                                name={fields["PROPERTY_369"].code}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Количество паллет по накладной"
                                name={fields["PROPERTY_365"].code}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Номер а/м"
                                name={fields["PROPERTY_403"].code}
                                rules={[
                                    {
                                        type: 'string',
                                        max: 12,
                                        message: 'Максимальное кол-во символов 12'
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^[A-ZА-Я0-9]+$/i
                                        ),
                                        message: "Большие буквы, пробелы не допускаются"
                                    }
                                ]}
                                tooltip={{
                                    title: 'Большие буквы, пробелы не допускаются, не больше 12 символов',
                                    icon: <InfoCircleOutlined/>
                                }}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="ФИО водителя"
                                name={fields["PROPERTY_391"].code}
                                rules={[
                                    {
                                        type: 'string',
                                        max: 35,
                                        message: 'Максимальное кол-во символов 35'
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^[а-яА-Я\s]+$/i
                                        ),
                                        message: "Только на рус.яз. Не должно быть специальных знаков (запятая, слэш и т.п.)"
                                    }
                                ]}
                                tooltip={{
                                    title: 'Только на рус.яз. ФИО может быть заполнено в любом формате, начиная с Фамилии. Не должно быть специальных знаков (запятая, слэш и т.п.). Не больше 35 букв',
                                    icon: <InfoCircleOutlined/>
                                }}
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Точка доставки"
                            >
                                <Select
                                    showSearch
                                    value={valueSearchTypeLocation}
                                    placeholder="Введите код"
                                    onSearch={(newValue) => {

                                        if (newValue) {

                                            if (timeoutSearchTypeLocation) {
                                                clearTimeout(timeoutSearchTypeLocation);
                                                timeoutSearchTypeLocation = null;
                                            }

                                            timeoutSearchTypeLocation = setTimeout(function () {

                                                if (newValue.length > 3) {

                                                    if (searchTypeLocationStatus == 'IDLE') {

                                                        dispatch(searchTypeLocation({code: newValue}));

                                                    }

                                                } else {
                                                    setDataSearchTypeLocation([]);
                                                }

                                            }, 300);

                                        } else {
                                            setDataSearchTypeLocation([]);
                                        }
                                    }}
                                    onChange={e => {

                                        // console.log(e);

                                        setValueSearchTypeLocation(e);

                                        formApp.setFieldValue(fields["PROPERTY_353"].code, e);

                                        let currentShip = dataSearchTypeLocation.filter(item => item.value == e);

                                        formApp.setFieldValue(fields["PROPERTY_355"].code, currentShip[0].label);
                                    }}
                                    defaultActiveFirstOption={false}
                                    showArrow={false}
                                    filterOption={false}
                                    notFoundContent={searchTypeLocationStatus != 'IDLE' ? <Spin size="small"/> : null}
                                    options={(dataSearchTypeLocation || []).map((d) => ({
                                        value: d.value,
                                        label: d.label + '(' + d.value + ')',
                                    }))}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                name={fields["PROPERTY_353"].code}
                                label="Код точки доставки"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name={fields["PROPERTY_355"].code}
                                label="Наименование точки доставки"
                            >
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="PROPERTY_407"
                                label="Комментарии"
                            >
                                <TextArea rows={4}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={24}>
                        <Col span={8}>
                            <Form.Item
                                label="Склад"
                                name={fields["PROPERTY_291"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста выберите склад',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Выберите склад"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={activeWarehousesOptions}
                                />
                                {/* <Select
                                    placeholder="Выберите склад"
                                >
                                    {infoWarehouses && Object.keys(infoWarehouses).map(idWarehouse => {
                                        let warehouse = infoWarehouses[idWarehouse];
                                        return (
                                            <Option value={idWarehouse}>{warehouse.NAME}</Option>
                                        )
                                    })}
                                </Select> */}
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Тип а/м"
                                name={fields["PROPERTY_339"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста выберите тип а/м',
                                    },
                                ]}
                            >
                                <Select
                                    showSearch
                                    placeholder="Введите название"
                                    filterOption={(input, option) =>
                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                    }
                                    options={typeTrucks.selectOptions}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                label="Загрузка/выгрузка:"
                                name={fields["PROPERTY_385"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста выберите вариант',
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Выберите вариант"
                                >
                                    <Option value={fields['PROPERTY_385'].options['399'].code}>Drop</Option>
                                    <Option value={fields['PROPERTY_385'].options['401'].code}>Pickup</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Space size={8}>
                            <Button type="primary" htmlType="submit">
                                Сохранить
                            </Button>
                            <Button onClick={e => {
                                setIsModalEditAppOpen(false);
                            }}>
                                Закрыть
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Информация по а/м"
                open={isModalAutoOpen}
                onOk={e => {
                    setIsModalAutoOpen(false);
                    formAuto.resetFields();
                }}
                onCancel={e => {
                    setIsModalAutoOpen(false);
                    formAuto.resetFields();
                }}
                width={600}
                footer={null}
            >
                <Form
                    name="basic"
                    layout="vertical"
                    form={formAuto}
                    style={{
                        maxWidth: 800,
                    }}
                    initialValues={{
                        [fields["PROPERTY_399"].code]: fields['PROPERTY_399'].options['409'].code,
                        [fields["PROPERTY_409"].code]: fields['PROPERTY_409'].options['413'].code,
                        [fields["PROPERTY_405"].code]: 'собственность',
                    }}
                    onFinish={e => {
                        let dataFormAuto = e;
                        if (!visibleDopFieldsExpeditor) {
                            if (!dataFormAuto[fields["PROPERTY_411"].code]) {
                                dataFormAuto[fields["PROPERTY_411"].code] = '';
                            }
                            if (!dataFormAuto[fields["PROPERTY_413"].code]) {
                                dataFormAuto[fields["PROPERTY_413"].code] = '';
                            }
                            if (!dataFormAuto[fields["PROPERTY_415"].code]) {
                                dataFormAuto[fields["PROPERTY_415"].code] = '';
                            }
                            if (!dataFormAuto[fields["PROPERTY_417"].code]) {
                                dataFormAuto[fields["PROPERTY_417"].code] = '';
                            }
                        }

                        if (!e.errorFields) {
                            if (updateBookingStatus == 'IDLE') {
                                dispatch(updateBooking({
                                    'ELEMENT_ID': defaultBookingInfo.ID,
                                    'FIELDS': {
                                        ...defaultBookingInfo,
                                        ...dataFormAuto,
                                        // [fields["PROPERTY_311"].code]: defaultBookingInfo[fields["PROPERTY_311"].code] && Object.values(defaultBookingInfo[fields["PROPERTY_311"].code])[0] == fields['PROPERTY_311'].options['371'].id && fields['PROPERTY_311'].options['367'].id || Object.values(defaultBookingInfo[fields["PROPERTY_311"].code])[0]
                                    },
                                    'UPDATE_FIELDS': {
                                        // TODO
                                        [fields["PROPERTY_391"].code]: dataFormAuto[fields["PROPERTY_391"].code],
                                        [fields["PROPERTY_393"].code]: dataFormAuto[fields["PROPERTY_393"].code],
                                        [fields["PROPERTY_395"].code]: dataFormAuto[fields["PROPERTY_395"].code],
                                        [fields["PROPERTY_397"].code]: dataFormAuto[fields["PROPERTY_397"].code],
                                        [fields["PROPERTY_399"].code]: dataFormAuto[fields["PROPERTY_399"].code],
                                        [fields["PROPERTY_401"].code]: dataFormAuto[fields["PROPERTY_401"].code],
                                        [fields["PROPERTY_403"].code]: dataFormAuto[fields["PROPERTY_403"].code],
                                        [fields["PROPERTY_405"].code]: dataFormAuto[fields["PROPERTY_405"].code],
                                        [fields["PROPERTY_407"].code]: dataFormAuto[fields["PROPERTY_407"].code],
                                        [fields["PROPERTY_409"].code]: dataFormAuto[fields["PROPERTY_409"].code],

                                        [fields["PROPERTY_411"].code]: !visibleNewFields ? dataFormAuto[fields["PROPERTY_411"].code] : '',
                                        [fields["PROPERTY_413"].code]: !visibleNewFields ? dataFormAuto[fields["PROPERTY_413"].code] : '',
                                        [fields["PROPERTY_415"].code]: !visibleNewFields ? dataFormAuto[fields["PROPERTY_415"].code] : '',
                                        [fields["PROPERTY_417"].code]: !visibleNewFields ? dataFormAuto[fields["PROPERTY_417"].code] : '',
                                        // ----------------------- Новые поля -----------------------
                                        [fields["PROPERTY_778"].code]: visibleNewFields ? DogovorSobstvennost[dataFormAuto[fields["PROPERTY_405"].code]] : '',
                                        [fields["PROPERTY_779"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_779"].code] : '',
                                        [fields["PROPERTY_780"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_780"].code] : '',
                                        [fields["PROPERTY_781"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_781"].code] : '',
                                        [fields["PROPERTY_783"].code]: visibleNewFields ? DogovorSobstvennost[dataFormAuto[fields["PROPERTY_405"].code]] : '',
                                        [fields["PROPERTY_784"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_784"].code] : '',
                                        [fields["PROPERTY_785"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_785"].code] : '',
                                        [fields["PROPERTY_758"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_758"].code] : '',
                                        [fields["PROPERTY_759"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_759"].code] : '',
                                        [fields["PROPERTY_760"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_760"].code] : '',
                                        [fields["PROPERTY_761"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_761"].code] : '',
                                        [fields["PROPERTY_762"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_762"].code] : '',
                                        [fields["PROPERTY_763"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_763"].code] : '',
                                        [fields["PROPERTY_776"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_776"].code] : '',
                                        [fields["PROPERTY_764"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_764"].code] : '',
                                        [fields["PROPERTY_765"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_765"].code] : '',
                                        [fields["PROPERTY_766"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_766"].code] : '',
                                        [fields["PROPERTY_767"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_767"].code] : '',
                                        [fields["PROPERTY_768"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_768"].code] : '',
                                        [fields["PROPERTY_769"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_769"].code] : '',
                                        [fields["PROPERTY_770"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_770"].code] : '',
                                        [fields["PROPERTY_771"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_771"].code] : '',
                                        [fields["PROPERTY_772"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_772"].code] : '',
                                        [fields["PROPERTY_773"].code]: visibleNewFields ? dataFormAuto[fields["PROPERTY_773"].code] : '',
                                        // -----------------------------------------------------------
                                        [fields["PROPERTY_425"].code]: dataFormAuto[fields["PROPERTY_425"].code],
                                    }
                                }));
                            }
                        }
                    }}
                    onFinishFailed={e => {
                        // console.log(e);
                    }}
                    autoComplete="off"
                >
                    <Form.Item
                        name={fields["PROPERTY_391"].code}
                        label="ФИО водителя"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательное поле'
                            },
                            {
                                type: 'string',
                                max: 35,
                                message: 'Максимальное кол-во символов 35'
                            },
                            {
                                pattern: new RegExp(
                                    /^[а-яА-Я\s]+$/i
                                ),
                                message: "Только на рус.яз. Не должно быть специальных знаков (запятая, слэш и т.п.)"
                            }
                        ]}
                        tooltip={{
                            title: 'Только на рус.яз. ФИО может быть заполнено в любом формате, начиная с Фамилии. Не должно быть специальных знаков (запятая, слэш и т.п.). Не больше 35 букв',
                            icon: <InfoCircleOutlined/>
                        }}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={fields["PROPERTY_393"].code}
                        label="Номер паспорта водителя"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательное поле'
                            },
                            {
                                type: 'string',
                                max: 150,
                                message: 'Максимальное кол-во символов 150'
                            },
                            {
                                pattern: new RegExp(
                                    /^[0-9/s]+$/i
                                ),
                                message: "Серия и Номер паспорта без пробелов. Только цифры."
                            }
                        ]}
                        tooltip={{
                            title: 'Серия и Номер паспорта без пробелов. В случае иностранного паспорта в этом поле запишите ноли (10 знаков), а номер внесите в Remarks',
                            icon: <InfoCircleOutlined/>
                        }}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={fields["PROPERTY_395"].code}
                        label="Номер телефона водителя"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательное поле'
                            },
                            {
                                type: 'string',
                                max: 10,
                                message: 'Максимальное кол-во символов 10'
                            },
                            {
                                pattern: new RegExp(
                                    /^[0-9]+$/i
                                ),
                                message: "Только цифры"
                            }
                        ]}
                        tooltip={{
                            title: 'Номер телефона без пробелов. В случае наличия дополнительных и иностранных номеров в этом поле запишите ноли (10 знаков), а номера внесите в Remarks',
                            icon: <InfoCircleOutlined/>
                        }}
                    >
                        <Input addonBefore="+7"/>
                    </Form.Item>
                    <Form.Item
                        name={fields["PROPERTY_397"].code}
                        label="ИНН водителя"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательное поле'
                            },
                            {
                                type: 'string',
                                max: 12,
                                message: 'Максимальное кол-во символов 12'
                            }
                        ]}
                    >
                        <Input/>
                    </Form.Item>

                    <Form.Item
                        name={fields["PROPERTY_401"].code}
                        label="Марка а/м"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательное поле'
                            },
                            {
                                type: 'string',
                                max: 9,
                                message: 'Максимальное кол-во символов 9'
                            },
                            {
                                pattern: new RegExp(
                                    /^[a-zA-Zа-яА-Я]+$/i
                                ),
                                message: "Только буквы (не цифры)"
                            }
                        ]}
                        tooltip={{title: 'Только буквы (не цифры), не больше 9 букв', icon: <InfoCircleOutlined/>}}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={fields["PROPERTY_403"].code}
                        label="Номер а/м"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательное поле'
                            },
                            {
                                type: 'string',
                                max: 12,
                                message: 'Максимальное кол-во символов 12'
                            },
                            {
                                pattern: new RegExp(
                                    /^[A-ZА-Я0-9]+$/i
                                ),
                                message: "Большие буквы, пробелы не допускаются"
                            }
                        ]}
                        tooltip={{
                            title: 'Большие буквы, пробелы не допускаются, не больше 12 символов',
                            icon: <InfoCircleOutlined/>
                        }}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name={fields["PROPERTY_405"].code}
                        label="Тип владения транспортом"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательное поле'
                            }
                        ]}
                        tooltip={{title: 'по отношению к Перевозчику', icon: <InfoCircleOutlined/>}}
                    >
                        <Select
                            placeholder="Выберите вариант"
                            onChange={e => {
                                if (!e.includes('собственность')) {
                                    setVisibleDopFieldsCar(true);
                                } else {
                                    setVisibleDopFieldsCar(false);

                                    formAuto.setFieldsValue({
                                        [fields["PROPERTY_778"].code]: '',
                                        [fields["PROPERTY_779"].code]: '',
                                        [fields["PROPERTY_780"].code]: '',
                                        [fields["PROPERTY_783"].code]: '',
                                        [fields["PROPERTY_784"].code]: '',
                                        [fields["PROPERTY_785"].code]: '',
                                    });
                                }
                            }}
                        >
                            <Option value="собственность">1 - собственность</Option>
                            <Option value="совместная собственность супругов">2 - совместная собственность
                                супругов</Option>
                            <Option value="аренда">3 - аренда</Option>
                            <Option value="лизинг">4 - лизинг</Option>
                            <Option value="безвозмездное пользование">5 - безвозмездное пользование</Option>
                        </Select>
                    </Form.Item>
                    {visibleNewFields && (visibleDopFieldsCar || (formAuto.getFieldValue(fields["PROPERTY_405"].code) && !formAuto.getFieldValue(fields["PROPERTY_405"].code).includes('собственность'))) && <>
                        <Form.Item
                            name={fields["PROPERTY_778"].code}
                            label="Название документа владения автомобилем/тягачом"
                        >
                            <Input disabled
                                   defaultValue={DogovorSobstvennost[formAuto.getFieldValue(fields["PROPERTY_405"].code)]}
                                   value={DogovorSobstvennost[formAuto.getFieldValue(fields["PROPERTY_405"].code)]}/>
                        </Form.Item>
                        <Form.Item
                            name={fields["PROPERTY_779"].code}
                            label="Номер документа владения автомобилем/тягачом"
                            rules={[
                                {
                                    required: true,
                                    message: 'Обязательное поле'
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="Дата документа владения автомобилем/тягачом"
                            name={fields["PROPERTY_780"].code}
                            rules={[
                                {
                                    required: true,
                                    message: 'Пожалуйста введите дату',
                                },
                            ]}
                        >
                            <DatePicker locale={locale}/>
                        </Form.Item>
                    </>}
                    <Form.Item
                        name={fields["PROPERTY_399"].code}
                        label="У а/м есть прицеп или полуприцеп?"
                        rules={[
                            {
                                required: true,
                                message: 'Обязательное поле'
                            },
                        ]}
                    >
                        <Select
                            placeholder="Выберите вариант"
                            onChange={e => {
                                if (e == fields['PROPERTY_399'].options['407'].code) {
                                    setVisibleDopFieldsPricep(true);
                                } else {
                                    setVisibleDopFieldsPricep(false);
                                }
                            }}
                        >
                            <Option value={fields['PROPERTY_399'].options['407'].code}>Да</Option>
                            <Option value={fields['PROPERTY_399'].options['409'].code}>Нет</Option>
                        </Select>
                    </Form.Item>
                    {(visibleDopFieldsPricep || formAuto.getFieldValue(fields["PROPERTY_399"].code) == fields['PROPERTY_399'].options['407'].code) && (<>
                        <Form.Item
                            name={fields["PROPERTY_425"].code}
                            label="Номер прицепа"
                            rules={[
                                {
                                    required: true,
                                    message: 'Обязательное поле'
                                },
                                {
                                    type: 'string',
                                    max: 12,
                                    message: 'Максимальное кол-во символов 12'
                                },
                                {
                                    pattern: new RegExp(
                                        /^[A-ZА-Я0-9]+$/i
                                    ),
                                    message: "Большие буквы, пробелы не допускаются"
                                }
                            ]}
                            tooltip={{
                                title: 'Большие буквы, пробелы не допускаются, не больше 12 символов',
                                icon: <InfoCircleOutlined/>
                            }}
                        >
                            <Input/>
                        </Form.Item>
                        {visibleNewFields && <>
                            <Form.Item
                                name={fields["PROPERTY_781"].code}
                                label="Марка прицепа/полуприцепа"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            {(visibleDopFieldsCar || (formAuto.getFieldValue(fields["PROPERTY_405"].code) && !formAuto.getFieldValue(fields["PROPERTY_405"].code).includes('собственность'))) && (<>
                                <Form.Item
                                    name={fields["PROPERTY_783"].code}
                                    label="Название документа владения прицепом/полуприцепом"
                                >
                                    <Input disabled
                                           defaultValue={DogovorSobstvennost[formAuto.getFieldValue(fields["PROPERTY_405"].code)]}
                                           value={DogovorSobstvennost[formAuto.getFieldValue(fields["PROPERTY_405"].code)]}/>
                                </Form.Item>
                                <Form.Item
                                    name={fields["PROPERTY_784"].code}
                                    label="Номер документа владения прицепом/полуприцепом"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Обязательное поле'
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>
                                <Form.Item
                                    label="Дата документа владения прицепом/полуприцепом"
                                    name={fields["PROPERTY_785"].code}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Пожалуйста введите дату',
                                        },
                                    ]}
                                >
                                    <DatePicker locale={locale}/>
                                </Form.Item>
                            </>)}
                        </>}
                    </>)}
                    <Form.Item
                        name={fields["PROPERTY_409"].code}
                        label="Привлекается ли к перевозке третья сторона (Перевозчик)?"
                        tooltip={{
                            title: '«да» - статус ТК по рейсу «Экспедитор»; «нет» - статус ТК по рейсу «Перевозчик»',
                            icon: <InfoCircleOutlined/>
                        }}
                    >
                        <Select
                            placeholder="Выберите вариант"
                            onChange={e => {
                                if (e == fields['PROPERTY_409'].options['411'].code) {
                                    setVisibleDopFieldsExpeditor(true);
                                } else {
                                    setVisibleDopFieldsExpeditor(false);
                                    formAuto.setFieldsValue({
                                        [fields["PROPERTY_411"].code]: '',
                                        [fields["PROPERTY_413"].code]: '',
                                        [fields["PROPERTY_415"].code]: '',
                                        [fields["PROPERTY_417"].code]: '',

                                        [fields["PROPERTY_758"].code]: '',
                                        [fields["PROPERTY_759"].code]: '',
                                        [fields["PROPERTY_787"].code]: '',
                                        [fields["PROPERTY_760"].code]: '',
                                        [fields["PROPERTY_776"].code]: '',
                                        [fields["PROPERTY_761"].code]: '',
                                        [fields["PROPERTY_762"].code]: '',
                                        [fields["PROPERTY_763"].code]: '',
                                        [fields["PROPERTY_764"].code]: '',
                                        [fields["PROPERTY_765"].code]: '',
                                        [fields["PROPERTY_766"].code]: '',
                                        [fields["PROPERTY_767"].code]: '',
                                        [fields["PROPERTY_768"].code]: '',
                                        [fields["PROPERTY_769"].code]: '',
                                        [fields["PROPERTY_770"].code]: '',
                                        [fields["PROPERTY_771"].code]: '',
                                        [fields["PROPERTY_772"].code]: '',
                                        [fields["PROPERTY_773"].code]: '',
                                    });
                                }
                            }}
                        >
                            <Option value={fields['PROPERTY_409'].options['411'].code}>Да</Option>
                            <Option value={fields['PROPERTY_409'].options['413'].code}>Нет</Option>
                        </Select>
                    </Form.Item>
                    {(visibleDopFieldsExpeditor || formAuto.getFieldValue(fields["PROPERTY_409"].code) == 411) && (
                        !visibleNewFields ? <>
                            <Form.Item
                                name={fields["PROPERTY_411"].code}
                                label="Название Перевозчика"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                    {
                                        type: 'string',
                                        max: 150,
                                        message: 'Максимальное кол-во символов 150'
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_413"].code}
                                label="Адрес Перевозчика"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                    {
                                        type: 'string',
                                        max: 150,
                                        message: 'Максимальное кол-во символов 150'
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_415"].code}
                                label="ИНН Перевозчика"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                    {
                                        type: 'string',
                                        max: 12,
                                        message: 'Максимальное кол-во символов 12'
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_417"].code}
                                label="Номер телефона Перевозчика"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                    {
                                        type: 'string',
                                        max: 10,
                                        message: 'Максимальное кол-во символов 10'
                                    }
                                ]}
                            >
                                <Input addonBefore="+7"/>
                            </Form.Item>
                        </> : <>
                            <Form.Item
                                name={fields["PROPERTY_758"].code}
                                label="№ заявки от экспедитора в адрес перевозчика"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                label="Дата заявки от экспедитора в адрес перевозчика"
                                name={fields["PROPERTY_759"].code}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Пожалуйста введите дату',
                                    },
                                ]}
                            >
                                <DatePicker locale={locale}/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_787"].code}
                                label="Сведения о форме организации"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                ]}
                            >
                                <Select
                                    placeholder="Выберите вариант"
                                    onChange={e => {
                                        if (e == "ООО") {
                                            setOrganizationIsOOO(true);

                                            formAuto.setFieldsValue({
                                                [fields["PROPERTY_761"].code]: '',
                                                [fields["PROPERTY_762"].code]: '',
                                                [fields["PROPERTY_763"].code]: '',
                                            });
                                        } else {
                                            setOrganizationIsOOO(false);

                                            formAuto.setFieldsValue({
                                                [fields["PROPERTY_776"].code]: '',
                                            });
                                        }
                                    }}
                                    defaultValue="ООО"
                                >
                                    <Option value="ООО">ООО</Option>
                                    <Option value="ИП">ИП</Option>
                                </Select>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_760"].code}
                                label="ИНН"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                    {
                                        type: 'string',
                                        max: 12,
                                        message: 'Максимальное кол-во символов 12'
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^[0-9]+$/i
                                        ),
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            {(organizationIsOOO
                                    || formAuto.getFieldValue(fields["PROPERTY_787"].code) == "ООО")
                                && formAuto.getFieldValue(fields["PROPERTY_787"].code) !== "ИП"
                                && <Form.Item
                                    name={fields["PROPERTY_776"].code}
                                    label="Название организации"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Обязательное поле'
                                        },
                                    ]}
                                >
                                    <Input/>
                                </Form.Item>}
                            {(!organizationIsOOO
                                    || formAuto.getFieldValue(fields["PROPERTY_787"].code) == "ИП")
                                && formAuto.getFieldValue(fields["PROPERTY_787"].code) !== "ООО"
                                && <>
                                    <Form.Item
                                        name={fields["PROPERTY_761"].code}
                                        label="Фамилия ИП"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Обязательное поле'
                                            },
                                            {
                                                pattern: new RegExp(
                                                    /^[а-яА-Я]+$/i
                                                ),
                                            }
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        name={fields["PROPERTY_762"].code}
                                        label="Имя ИП"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Обязательное поле'
                                            },
                                            {
                                                pattern: new RegExp(
                                                    /^[а-яА-Я]+$/i
                                                ),
                                            }
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        name={fields["PROPERTY_763"].code}
                                        label="Отчество ИП"
                                        rules={[
                                            {
                                                pattern: new RegExp(
                                                    /^[а-яА-Я]+$/i
                                                ),
                                            }
                                        ]}
                                    >
                                        <Input/>
                                    </Form.Item>
                                </>}

                            <Form.Item
                                name={fields["PROPERTY_764"].code}
                                label="Индекс"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^[0-9]+$/i
                                        ),
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_765"].code}
                                label="Регион"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_766"].code}
                                label="Район"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_767"].code}
                                label="Город"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_768"].code}
                                label="Населенный пункт"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_769"].code}
                                label="Улица"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_770"].code}
                                label="Дом"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^[0-9]+$/i
                                        ),
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_771"].code}
                                label="Корпус"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                ]}
                            >
                                <Input/>
                            </Form.Item>
                            <Form.Item
                                name={fields["PROPERTY_772"].code}
                                label="Квартира"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^[0-9]+$/i
                                        ),
                                    }
                                ]}
                            >
                                <Input/>
                            </Form.Item>

                            <Form.Item
                                name={fields["PROPERTY_773"].code}
                                label="Контакты: Телефон"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Обязательное поле'
                                    },
                                    {
                                        type: 'string',
                                        max: 10,
                                        message: 'Максимальное кол-во символов 10'
                                    },
                                    {
                                        pattern: new RegExp(
                                            /^[0-9]+$/i
                                        ),
                                        message: "Только цифры"
                                    }
                                ]}
                                tooltip={{
                                    title: 'Номер телефона без пробелов. В случае наличия дополнительных и иностранных номеров в этом поле запишите ноли (10 знаков), а номера внесите в Remarks',
                                    icon: <InfoCircleOutlined/>
                                }}
                            >
                                <Input addonBefore="+7"/>
                            </Form.Item>
                        </>
                    )}
                    <Form.Item
                        name={fields["PROPERTY_407"].code}
                        label="Комментарии"
                    >
                        <TextArea rows={2}/>
                    </Form.Item>
                    <Form.Item>
                        <Space size={8}>
                            {/* <Button type="primary" htmlType="submit">
                                Сохранить
                            </Button> */}
                            <Dropdown.Button
                                trigger={['click']}
                                dropdownRender={() => {
                                    return (
                                        <div style={contentStyle}>
                                            <Button
                                                style={{textAlign: 'left'}}
                                                onClick={e => {

                                                    setVisibleDopFieldsExpeditor(false);
                                                    setVisibleDopFieldsPricep(false);

                                                    if (updateBookingStatus == 'IDLE') {
                                                        dispatch(updateBooking({
                                                            'ELEMENT_ID': defaultBookingInfo.ID,
                                                            'FIELDS': {
                                                                ...defaultBookingInfo,
                                                                [fields["PROPERTY_407"].code]: formAuto.getFieldValue(fields["PROPERTY_407"].code),
                                                            },
                                                            'UPDATE_FIELDS': {
                                                                [fields["PROPERTY_407"].code]: formAuto.getFieldValue(fields["PROPERTY_407"].code),
                                                            }
                                                        }));
                                                    }

                                                }}
                                            >Сохранить комментарий</Button>
                                        </div>
                                    )
                                }}
                                htmlType="submit"
                                // onClick={e => {
                                //     formAuto.resetFields();
                                //     setVisibleDopFieldsExpeditor(false);
                                //     setVisibleDopFieldsPricep(false);
                                // }}
                            >Сохранить</Dropdown.Button>

                            <Dropdown.Button
                                trigger={['click']}
                                dropdownRender={() => {
                                    return (
                                        <div style={contentStyle}>
                                            <Button
                                                style={{textAlign: 'left'}}
                                                onClick={e => {

                                                    formAuto.resetFields();
                                                    setVisibleDopFieldsExpeditor(false);
                                                    setVisibleDopFieldsPricep(false);

                                                    if (updateBookingStatus == 'IDLE') {
                                                        dispatch(updateBooking({
                                                            'ELEMENT_ID': defaultBookingInfo.ID,
                                                            'FIELDS': {
                                                                ...defaultBookingInfo,
                                                                [fields["PROPERTY_391"].code]: null,
                                                                [fields["PROPERTY_393"].code]: null,
                                                                [fields["PROPERTY_395"].code]: null,
                                                                [fields["PROPERTY_397"].code]: null,
                                                                [fields["PROPERTY_399"].code]: null,
                                                                [fields["PROPERTY_401"].code]: null,
                                                                [fields["PROPERTY_403"].code]: null,
                                                                [fields["PROPERTY_405"].code]: null,
                                                                [fields["PROPERTY_407"].code]: null,
                                                                [fields["PROPERTY_409"].code]: null,
                                                                [fields["PROPERTY_411"].code]: null,
                                                                [fields["PROPERTY_413"].code]: null,
                                                                [fields["PROPERTY_415"].code]: null,
                                                                [fields["PROPERTY_417"].code]: null,
                                                                [fields["PROPERTY_425"].code]: null,
                                                                // ------- новые поля -------------
                                                                [fields["PROPERTY_778"].code]: null,
                                                                [fields["PROPERTY_779"].code]: null,
                                                                [fields["PROPERTY_780"].code]: null,
                                                                [fields["PROPERTY_781"].code]: null,
                                                                [fields["PROPERTY_783"].code]: null,
                                                                [fields["PROPERTY_784"].code]: null,
                                                                [fields["PROPERTY_785"].code]: null,
                                                                [fields["PROPERTY_758"].code]: null,
                                                                [fields["PROPERTY_759"].code]: null,
                                                                [fields["PROPERTY_760"].code]: null,
                                                                [fields["PROPERTY_761"].code]: null,
                                                                [fields["PROPERTY_762"].code]: null,
                                                                [fields["PROPERTY_763"].code]: null,
                                                                [fields["PROPERTY_776"].code]: null,
                                                                [fields["PROPERTY_764"].code]: null,
                                                                [fields["PROPERTY_765"].code]: null,
                                                                [fields["PROPERTY_766"].code]: null,
                                                                [fields["PROPERTY_767"].code]: null,
                                                                [fields["PROPERTY_768"].code]: null,
                                                                [fields["PROPERTY_769"].code]: null,
                                                                [fields["PROPERTY_770"].code]: null,
                                                                [fields["PROPERTY_771"].code]: null,
                                                                [fields["PROPERTY_772"].code]: null,
                                                                [fields["PROPERTY_773"].code]: null,
                                                            },
                                                            'UPDATE_FIELDS': {
                                                                [fields["PROPERTY_391"].code]: null,
                                                                [fields["PROPERTY_393"].code]: null,
                                                                [fields["PROPERTY_395"].code]: null,
                                                                [fields["PROPERTY_397"].code]: null,
                                                                [fields["PROPERTY_399"].code]: null,
                                                                [fields["PROPERTY_401"].code]: null,
                                                                [fields["PROPERTY_403"].code]: null,
                                                                [fields["PROPERTY_405"].code]: null,
                                                                [fields["PROPERTY_407"].code]: null,
                                                                [fields["PROPERTY_409"].code]: null,
                                                                [fields["PROPERTY_411"].code]: null,
                                                                [fields["PROPERTY_413"].code]: null,
                                                                [fields["PROPERTY_415"].code]: null,
                                                                [fields["PROPERTY_417"].code]: null,
                                                                [fields["PROPERTY_425"].code]: null,
                                                                // ------- новые поля -------------
                                                                [fields["PROPERTY_778"].code]: null,
                                                                [fields["PROPERTY_779"].code]: null,
                                                                [fields["PROPERTY_780"].code]: null,
                                                                [fields["PROPERTY_781"].code]: null,
                                                                [fields["PROPERTY_783"].code]: null,
                                                                [fields["PROPERTY_784"].code]: null,
                                                                [fields["PROPERTY_785"].code]: null,
                                                                [fields["PROPERTY_758"].code]: null,
                                                                [fields["PROPERTY_759"].code]: null,
                                                                [fields["PROPERTY_760"].code]: null,
                                                                [fields["PROPERTY_761"].code]: null,
                                                                [fields["PROPERTY_762"].code]: null,
                                                                [fields["PROPERTY_763"].code]: null,
                                                                [fields["PROPERTY_776"].code]: null,
                                                                [fields["PROPERTY_764"].code]: null,
                                                                [fields["PROPERTY_765"].code]: null,
                                                                [fields["PROPERTY_766"].code]: null,
                                                                [fields["PROPERTY_767"].code]: null,
                                                                [fields["PROPERTY_768"].code]: null,
                                                                [fields["PROPERTY_769"].code]: null,
                                                                [fields["PROPERTY_770"].code]: null,
                                                                [fields["PROPERTY_771"].code]: null,
                                                                [fields["PROPERTY_772"].code]: null,
                                                                [fields["PROPERTY_773"].code]: null,
                                                            }
                                                        }));
                                                    }

                                                }}
                                            >Очистить и сохранить</Button>
                                        </div>
                                    )
                                }}
                                onClick={e => {
                                    formAuto.resetFields();
                                    setVisibleDopFieldsExpeditor(false);
                                    setVisibleDopFieldsPricep(false);
                                }}
                            >Очистить</Dropdown.Button>

                            <Button onClick={e => {
                                setIsModalAutoOpen(false);
                                setVisibleDopFieldsPricep(false);
                                formAuto.resetFields();
                            }}>
                                Закрыть
                            </Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="Выберите время"
                open={isModalTimesOpen}
                onOk={e => {
                    setIsModalTimesOpen(false);
                    setShowSelectDock(null);
                    dispatch(getFreeCellsClear());
                }}
                onCancel={e => {
                    setIsModalTimesOpen(false);
                    setShowSelectDock(null);
                    dispatch(getFreeCellsClear());
                    setDateBookingsSelect(dateBookings);
                }}
                footer={null}
                width={'80vw'}
            >
                {showSelectDock && (
                    <div>
                        <div style={{marginBottom: 40}}>
                            {/* <input type="date" value={dateBookingsSelect} pattern="\d{2}.\d{2}.\d{4}" onChange={e => {
                                setDateBookingsSelect(e.target.value);
                            }} /> */}
                            <DatePicker
                                locale={locale}
                                size="large"
                                defaultValue={dateBookings}
                                value={dateBookingsSelect}
                                format={'DD.MM.YYYY'}
                                onChange={(date, dateString) => {
                                    // console.log(dateString);
                                    // setDateBookings(date);
                                    if (date) {
                                        setDateBookingsSelect(date);
                                    }
                                    // setDateBookings(dayjs(e).date()+'.'+dayjs(e).month()+'.'+dayjs(e).year());
                                }}
                            />
                        </div>
                        {statusGetFreeCells == 'LOADING' && (
                            <Spin/>
                        )}
                        {resultGetFreeCells && (
                            <div>
                                {Object.keys(resultGetFreeCells).map(dockId => {
                                    return (
                                        <div style={{marginBottom: 8}}>
                                            {resultGetFreeCells[dockId] && resultGetFreeCells[dockId].length > 0 && (
                                                <Card
                                                    size="small"
                                                    title={infoDocks[dockId].NAME}
                                                    extra={null}
                                                >
                                                    <Space size={4} wrap>
                                                        {resultGetFreeCells[dockId] && resultGetFreeCells[dockId].map(timeId => {

                                                            let duration = getValueProperty(showSelectDock.booking, fields["PROPERTY_317"].id);

                                                            if (duration && duration.indexOf('.') >= 0 && f(duration) > 2) {
                                                                duration = duration[0] + '.' + duration[2] + '' + duration[3];
                                                            }

                                                            if (getValueProperty(showSelectDock.booking, fields["PROPERTY_419"].id) != '') {
                                                                duration = getValueProperty(showSelectDock.booking, fields["PROPERTY_419"].id);
                                                            }

                                                            // let findIndexTime = warehouse.timeWorkList.indexOf(timeId);
                                                            let findIndexTime = Object.values(timeInfo).findIndex(item => item.ID == timeId);

                                                            let dragTimes = [timeId];

                                                            let durationFloat = duration && duration.indexOf('.') >= 0 && duration.split('.').length > 1 && duration.split('.')[1] || false;

                                                            duration = Math.ceil(duration);

                                                            if (showSelectDock.warehouse && showSelectDock.warehouse.PROPERTY_315 && Object.values(showSelectDock.warehouse.PROPERTY_315)[0] == 375) {

                                                                if (durationFloat && durationFloat / 100 <= 0.5) {
                                                                    duration = duration - 0.5;
                                                                }

                                                                duration = duration * 2;
                                                            }

                                                            // if(warehouse && warehouse.PROPERTY_315 && Object.values(warehouse.PROPERTY_315)[0] == 373){
                                                            //     forDuration = parseInt(duration * 2) - 1
                                                            // duration = duration + 1;
                                                            // }

                                                            if (duration > 1) {

                                                                if (showSelectDock.warehouse && showSelectDock.warehouse.PROPERTY_315 && Object.values(showSelectDock.warehouse.PROPERTY_315)[0] == 373) {
                                                                    duration = duration * 2 - 1;
                                                                }

                                                                for (let index = 1; index < duration; index++) {

                                                                    // dragTimes.push(warehouse.timeWorkList[findIndexTime + index]);
                                                                    dragTimes.push(Object.keys(timeInfo)[findIndexTime + index]);

                                                                }
                                                            }

                                                            return (
                                                                <Popconfirm
                                                                    placement="top"
                                                                    title={'Выбрать слот?'}
                                                                    onConfirm={e => {
                                                                        if (statusCheckedBlockedCellsDate == 'IDLE') {

                                                                            setDragBooking(showSelectDock.booking);
                                                                            setDragDock(dockId);
                                                                            setDragTimes(dragTimes);

                                                                            dispatch(checkedBlockedCellsDate({
                                                                                dateBookings: dateBookingsSelect.format('DD.MM.YYYY'),
                                                                                dockId: dockId,
                                                                                timeList: dragTimes,
                                                                                timeListAll: timeList,
                                                                                booking: showSelectDock.booking,
                                                                                dragBooking: showSelectDock.booking
                                                                            }));

                                                                        }
                                                                    }}
                                                                    okText="Да"
                                                                    cancelText="Нет"
                                                                >
                                                                    <Button
                                                                        size={'small'}
                                                                        style={{marginRight: 10, marginBottom: 10}}
                                                                    >{timeInfo[timeId].NAME}</Button>
                                                                </Popconfirm>
                                                                // <Button size={'small'} style={{ marginRight: 10, marginBottom: 10 }} onClick={e => {

                                                                //     // console.log(dragTimes);

                                                                //     if(statusCheckedBlockedCellsDate == 'IDLE'){

                                                                //         setDragBooking(showSelectDock.booking);
                                                                //         setDragDock(dockId);
                                                                //         setDragTimes(dragTimes);

                                                                //         dispatch(checkedBlockedCellsDate({
                                                                //             dateBookings: dateBookingsSelect.format('DD.MM.YYYY'),
                                                                //             dockId: dockId,
                                                                //             timeList: dragTimes,
                                                                //             timeListAll: timeList,
                                                                //             booking: showSelectDock.booking
                                                                //         }));

                                                                //     }

                                                                // }}>{timeInfo[timeId].NAME}</Button>
                                                            )
                                                        })}
                                                    </Space>
                                                </Card>
                                            )}
                                        </div>
                                    )
                                })}
                            </div>
                        )}
                        <Button onClick={e => {
                            setIsModalTimesOpen(false);
                            setShowSelectDock(null);
                            dispatch(getFreeCellsClear());
                            setDateBookingsSelect(dateBookings);
                        }}>
                            Закрыть
                        </Button>
                    </div>
                )}
            </Modal>
        </>
    )
}

export default Booking;